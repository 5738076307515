import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CookieService {

    constructor(@Inject(DOCUMENT) private dom: Document){ }

    /**
     * To extract the cookie - please make sure you call this after checking commonService.isPlatformBrowser();
     * @param name 
     * @returns 
     */
    getCookie(name: string) {
        function escape(s: string) { return s.replace(/([.*+?\^${}()|\[\]\/\\])/g, '\\$1'); };
        let match = this.dom.cookie.match(RegExp('(?:^|;\\s*)' + escape(name) + '=([^;]*)'));
        return match ? match[1] : null;
    }

    /**
     * To create a cookie - please make sure you call this after checking commonService.isPlatformBrowser();
     * @param name 
     * @param value 
     * @param days 
     */
    createCookie(name: string, value: string, days: number) {
        let expires;

        if (days) {
            let date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        } else {
            expires = "";
        }
        this.dom.cookie = encodeURIComponent(name) + "=" + encodeURIComponent(value) + expires + "; path=/";
    }

    /**
     * To erase a cookie - please make sure you call this after checking commonService.isPlatformBrowser();
     * @param name 
     */
    eraseCookie(name:  string) {
        this.createCookie(name,"",-1);
    }
}

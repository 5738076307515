import { isPlatformBrowser } from '@angular/common';
import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { Observable, fromEvent, map, startWith } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  
  sm$!: Observable<boolean>;
  md$!: Observable<boolean>;
  desktopBaseDown$!: Observable<boolean>;

  
  constructor(@Inject(PLATFORM_ID) private platformId: Object,) {
    console.log("Platform ID", this.platformId);
    if (isPlatformBrowser(this.platformId)) {
      this.sm$ = this.media(`(max-width: 767px)`);
      this.md$ = this.media(`(min-width: 768px) and (max-width: 1023px)`);
      this.desktopBaseDown$ = this.media(`(max-width: 1023px)`);
    }
  }

  media(query: string): Observable<boolean> {
    const mediaQuery = window.matchMedia(query);
    return fromEvent<MediaQueryList>(mediaQuery, 'change').pipe(
      startWith(mediaQuery),
      map((list: MediaQueryList) => list.matches)
    );
  }

  isPlatformBrowser(): boolean {
    return isPlatformBrowser(this.platformId);
  }

}

import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { CommonService } from 'src/app/core/services/common.service';
import {  ComponentType } from '../../config/app.config';

@Component({
  selector: 'app-horizontal-tabs',
  templateUrl: './horizontal-tabs.component.html',
  styleUrls: ['./horizontal-tabs.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class HorizontalTabsComponent implements OnInit {
  @Input({ required: true }) public horizontalTabs!: any;
  ComponentType = ComponentType;
  activeTab: any;
  activeAccordion: any;
  tabbedTitles: Array<any> = [];
  wrapperBackgroundColor: string = '';
  headerOnly: string = '';
  hasTabbedItems: boolean = false;
  tabbedItems: Array<any> = [];

  constructor(public commonService: CommonService) { 

  }

  ngOnInit(): void {
    this.wrapperBackgroundColor = this.horizontalTabs?.backgroundColor == "White" ? "Grey" : "White";
    this.headerOnly = this.horizontalTabs.headerTag && !this.horizontalTabs?.introCopy ? "headerOnly" : "";
    this.hasTabbedItems = this.horizontalTabs?.tabbedContentCollection?.items?.length;
    if (this.hasTabbedItems) {
      this.tabbedItems = JSON.parse(JSON.stringify(this.horizontalTabs.tabbedContentCollection.items));
      this.tabbedItems.forEach((item)=> {
        if(item.__typename == "ContentBlockWithRightRailCallout") {
          item.header = item.contentBlockCollection.items[0].header;
        }
      })
      if (this.tabbedItems?.length > 0) {
        this.activeTab = 0;
      }
    }
  }
}
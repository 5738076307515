import {gql} from 'apollo-angular'
import { FOOTER_FIELDS, HEADER_FIELDS, LINK_FIELDS, DISCLAIMER_ITEM_FIELDS } from 'src/app/gql'

const GET_SIF_Collection =  gql`
query requestInformation($slug: String!, $preview: Boolean! = false) {
  requestInformationPageTemplateCollection(where: { url: $slug }, preview: $preview, limit: 1) {
    items {
      hTag
      header
      pageTitleExternal
      metaTitle
      metaOgImage {
        url
      }
      metaDescription
      canonical
      robotsTag
      form {
        header
        hTag
        subhead
        zipCodeLabel
        zipErrorMessage
        degreeLevelLabel
        degreeLevelErrorMessage
        aosLabel
        aosErrorMessage
        programLabel
        programErrorMessage
        sessionLabel
        sessionErrorMessage
        firstNameLabel
        firstNameErrorMessage
        lastNameLabel
        lastNameErrorMessage
        emailLable
        emailErrorMessage       
        countryregionLabel
        countryregionErrorMessage
        countryCodeLabel
        countryCodeErrorMessage
        phoneLabel
        phoneErrorMessage
        linkTitle
        militaryQuestionLabel
        tcpaDisclaimer {
          ... DISCLAIMER_ITEM_FIELDS
        }
        tooltipTitle
        tooltipCopy {
          json
        }
        gdprDisclaimer {
          ... DISCLAIMER_ITEM_FIELDS
        }
        gdprCheckBoxNotice {
          json
        }
      }
      scheduleAnAppointment {
        header
				content {
          json
        }
        linksCollection {
          items {
            ... LINK_FIELDS
          }
        }
      }
      thankYou {
        header
				content {
          json
        }
        linksCollection {
          items {
            ... LINK_FIELDS
          }
        }
      }
      suppressionThankYou {
        header
				content {
          json
        }
        linksCollection {
          items {
            ... LINK_FIELDS
          }
        }
      }
      overrideHeader {
        ...HEADER_FIELDS
      }
      overrideFooter {
        ...FOOTER_FIELDS
      }
    }
  }
}
${LINK_FIELDS}
${DISCLAIMER_ITEM_FIELDS}
${HEADER_FIELDS}
${FOOTER_FIELDS}`

export {GET_SIF_Collection}


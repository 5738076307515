import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-content-block-right-rail',
  templateUrl: './content-block-right-rail.component.html',
  styleUrls: ['./content-block-right-rail.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class ContentBlockRightRailComponent implements OnInit{
  @Input({ required: true }) public contentBlock: any = {};
  rightRailBoxes: Array<any> = [];

  constructor() {

  }

  ngOnInit(): void {
    if (this.contentBlock.rightRailBoxesCollection?.items?.length) {
      this.rightRailBoxes = JSON.parse(JSON.stringify(this.contentBlock.rightRailBoxesCollection?.items));
      this.checkForImageOnlyRRBoxes()
    }
  }

  checkForImageOnlyRRBoxes() {
    this.rightRailBoxes =  this.rightRailBoxes.map((item: any) => {
      return {...item, ...{imageOnly: this.isImageOnly(item)}}
    })
  }

  isImageOnly(contentBlock: any) {
    if(contentBlock.header || 
      contentBlock.rightRailLinksCollection.items.length > 0 || 
      contentBlock.mainContent)
      {
        return false;
      }
      return true;
  }

}

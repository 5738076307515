import {Component, Input, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import { ModalService } from 'src/app/core/services/modal.service';
import { CommonService } from 'src/app/core/services/common.service';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.less'],
})
export class ModalComponent implements OnInit {

  display$: Observable<'open' | 'close'> | undefined;

  constructor(
      private modalService: ModalService,
      public commonService: CommonService
  ) {}

  @Input() content: any;
  
  ngOnInit() {
    this.display$ = this.modalService.watch();    
  }

  close() {
    this.modalService.close();
  }
}
<section *ngIf="!formSubmitted" class="White">
    <div class="reqInfo">
        <div class='grid-container sif-container'>
                <div class="upper-container"> 
                        <app-h-tag [hTag]="pageData?.form?.hTag" [className]="'header h2'" [content]="pageData?.form?.header"></app-h-tag>
                        <div class='text-content' *ngIf="pageData?.form?.subhead">
                          <p>{{pageData?.form?.subhead}}</p>
                        </div>
                </div>
                <div class="lower-container">
                    <div class='small-12'>
                      <form [formGroup]="requestInfoForm" autocomplete="off" (ngSubmit)="onSubmit()">
                        <div class="fields-wrapper">
                          <div class="zip" *ngIf="isDomestic && internationalValidationCompleted">
                            <label for="zip">{{pageData?.form?.zipCodeLabel}}</label>
                            <input id="zip" numbersOnly maxlength="5" minlength="5" type="text" (change)="validateZipCode($event)" formControlName="zip">
                            <p *ngIf="formControl['zip'].touched && formControl['zip'].errors" class="text-danger">{{pageData?.form?.zipErrorMessage}}</p>
                          </div>
                          <div class="degree">
                            <label for="degree">{{pageData?.form?.degreeLevelLabel}}</label>
                            <div class="select-wrapper">
                              <select id="degree" formControlName="degree" (change)="changedDegreeLevel($event)" required>
                                <option selected disabled value="">Select Degree Type</option>
                                <option *ngFor="let level of degreeLevel" [value]="level.code">
                                  {{ level.name }}
                                </option>
                              </select>
                            </div>
                            <p *ngIf="formControl['degree'].touched && formControl['degree'].errors" class="text-danger">{{pageData?.form?.degreeLevelErrorMessage}}</p>
                          </div>
                        </div>

                        <div class="areaOfStudy">
                          <label for="areaOfStudy">{{pageData?.form?.aosLabel}}</label>
                          <div class="select-wrapper">
                            <select id="areaOfStudy" formControlName="areaOfStudy" (change)="changedAOS($event)">
                              <option selected disabled value="">Select Interest</option>
                              <option *ngFor="let area of aos" [value]="area">
                                {{ area }}
                              </option>
                            </select>
                          </div>
                          <p *ngIf="formControl['areaOfStudy'].touched && formControl['areaOfStudy'].errors" class="text-danger">{{pageData?.form?.aosErrorMessage}}</p>
                        </div>

                        <div class="programName">
                          <label for="programName">{{pageData?.form?.programLabel}}</label>
                          <div class="select-wrapper">
                            <select id="programName" formControlName="programName" (change)="changedProgram($event)">
                              <option selected disabled value="">Select Program</option>
                              <option *ngFor="let program of programs" [value]="program">
                                {{ program }}
                              </option>
                            </select>
                          </div>
                          <p *ngIf="formControl['programName'].touched && formControl['programName'].errors" class="text-danger">{{pageData?.form?.programErrorMessage}}</p>
                        </div>

                        <div class="startDate">
                          <label for="startDate">{{pageData?.form?.sessionLabel}}</label>
                          <div class="select-wrapper">
                            <select id="startDate" formControlName="startDate">
                              <option selected disabled value="">Select Session</option>
                              <option *ngFor="let session of sessions" [value]="session">
                                {{ session }}
                              </option>
                            </select>
                          </div>
                          <p *ngIf="formControl['startDate'].touched && formControl['startDate'].errors" class="text-danger">{{pageData?.form?.sessionErrorMessage}}</p>
                        </div>

                        <div class="name ottra">
                          <label for="Name">name</label>
                          <input id="Name" type="text" formControlName="name" autocomplete="disabled" data-lpignore="true" tabindex="-1" aria-hidden="true">                         
                        </div>

                        <div class="fields-wrapper">
                          <div class="firstName">
                            <label for="first-name">{{pageData?.form?.firstNameLabel}}</label>
                            <input id="first-name" type="text" inputRef (blur)="updateFieldValue('firstName')" formControlName="firstName">
                            <p *ngIf="formControl['firstName'].touched && formControl['firstName'].errors" class="text-danger">{{pageData?.form?.firstNameErrorMessage}}</p>
                          </div>
                          <div class="lastName">
                            <label for="last-name">{{pageData?.form?.lastNameLabel}}</label>
                            <input id="last-name" type="text" inputRef (blur)="updateFieldValue('lastName')" formControlName="lastName">
                            <p *ngIf="formControl['lastName'].touched && formControl['lastName'].errors" class="text-danger">{{pageData?.form?.lastNameErrorMessage}}</p>
                          </div>
                        </div>

                        <div class="email ottra">
                          <label for="Email">Email</label>
                          <input id="Email" type="email" formControlName="email" autocomplete="off" data-lpignore="true" tabindex="-1" aria-hidden="true">                          
                        </div>
                        <div class="sifEmail" *ngIf="!isDomestic">
                          <label for="sifEmail">{{pageData?.form?.emailLable}}</label>
                          <input id="sifEmail" type="email" formControlName="sifEmail">
                          <p *ngIf="formControl['sifEmail'].touched && formControl['sifEmail'].errors" class="text-danger">{{pageData?.form?.emailErrorMessage}}</p>
                        </div>
                        <div class="fields-wrapper" *ngIf="isDomestic">
                          <div class="sifEmail">
                            <label for="sifEmail">{{pageData?.form?.emailLable}}</label>
                            <input id="sifEmail" type="email" formControlName="sifEmail">
                            <p *ngIf="formControl['sifEmail'].touched && formControl['sifEmail'].errors" class="text-danger">{{pageData?.form?.emailErrorMessage}}</p>
                          </div>
                          <div class="phone">
                            <label for="phone">{{pageData?.form?.phoneLabel}}</label>
                            <input [mobMask]="maskphone" id="phone" type="text" formControlName="phone" maxlength="20">
                            <p *ngIf="formControl['phone'].touched && formControl['phone'].errors" class="text-danger">{{pageData?.form?.phoneErrorMessage}}</p>
                          </div>
                        </div>
                        <div class="country" *ngIf="showCountryField">
                          <label for="country">{{pageData?.form?.countryregionLabel}}</label>
                          <div class="select-wrapper">
                            <select id="country" formControlName="country" (change)="changedCountryCode($event)">
                              <option selected disabled value="">Select Country</option>
                              <option *ngFor="let code of countryCodes" [value]="code">
                                {{ code }}
                              </option>
                            </select>
                          </div>
                          <p *ngIf="formControl['country'].touched && formControl['country'].errors" class="text-danger">{{pageData?.form?.countryregionErrorMessage}}</p>
                        </div>
                        <div class="fields-wrapper">
                          <div class="phoneCountryCode" *ngIf="showCountryField && !isDomestic">
                            <label for="phoneCountryCode">{{pageData?.form?.countryCodeLabel}}</label>
                            <input numbersOnly type="text" formControlName="phoneCountryCode" maxlength="5" />
                            <p *ngIf="formControl['phoneCountryCode'].touched && formControl['phoneCountryCode'].errors" class="text-danger">{{pageData?.form?.countryCodeErrorMessage}}</p>
                          </div>
                          <div class="phone" *ngIf="!isDomestic">
                            <label for="phone">{{pageData?.form?.phoneLabel}}</label>
                            <input [mobMask]="maskphone" id="phone" type="text" formControlName="phone" maxlength="20">
                            <p *ngIf="formControl['phone'].touched && formControl['phone'].errors" class="text-danger">{{pageData?.form?.phoneErrorMessage}}</p>
                          </div>
                        </div>
                        <div class="fields-wrapper">
                          <div class="military checkbox-wrapper">
                            <input type="checkbox" formControlName="military" id="military" class="form-check-input" />
                            <label for="military">{{pageData?.form?.militaryQuestionLabel}}</label>
                          </div>
                        </div>   
                        <div class='disclaimer show-for-tablet'>
                          <div class="tcpa-disclaimer" *ngIf="pageData?.form?.tcpaDisclaimer && !isGDPR">
                            <app-disclaimer-item [disclaimerItem]="pageData?.form?.tcpaDisclaimer"></app-disclaimer-item>
                            <a *ngIf="isDomestic && pageData?.form?.tooltipTitle && pageData?.form?.tooltipCopy?.json"
                               class="messageTooltip" role="tooltip" tabindex="0">
                              {{pageData.form.tooltipTitle}}
                              <app-contentful-rich-text class="tooltiptext" [document]="pageData.form.tooltipCopy.json"></app-contentful-rich-text>
                            </a>
                          </div>
                          <div class="gdpr-disclaimer" *ngIf="pageData?.form?.gdprDisclaimer && isGDPR">
                            <input type="checkbox" name="isAccept" [checked]="disclaimerAccepted" (change)="disclaimerAccepted = !disclaimerAccepted;">
                            <app-disclaimer-item [disclaimerItem]="pageData?.form?.gdprDisclaimer"></app-disclaimer-item>
                          </div>
                        </div>                     
                        <div id="challenge" class="challenge" *ngIf="recaptchaLoaded">
                          <re-captcha (resolved)="captchaChallengeResolved($event)" formControlName="recaptchaChallengeToken"                                      
                                      theme="light" type="image" size="normal" required></re-captcha>
                        </div>
                        <div class="button-container">
                          <button type="submit" class="btn primary-button" [disabled]="requestInfoForm.invalid">
                            {{isSubmitting?'SUBMITTING...':pageData?.form?.linkTitle}}
                            <div id="newSifSubmitLoader" class="sifSubmitLoader" [ngClass]="{'active': isSubmitting === true}"></div>
                          </button>
                        </div>
                      </form>
                    </div>
                </div>
        
        </div>
    </div>
</section>

<app-modal *ngIf="pageData?.form?.gdprCheckBoxNotice" [content]="pageData?.form?.gdprCheckBoxNotice"></app-modal>

<!-- Post SIF messages  -->
<section class="White" *ngIf="formSubmitted && !ycbmSubmitted">
    <div class="thankyou">
        <div class='grid-container'>
            <div class='grid-x'>
              <div class="content-container text-center">
                <div class="schedule-now-intro" *ngIf="!isSkip && showBookingButton">
                  <h2 class="header h2" *ngIf="pageData?.scheduleAnAppointment?.header">{{pageData?.scheduleAnAppointment?.header}}</h2>
                  <div class="subheader-text" *ngIf="pageData?.scheduleAnAppointment?.subhead">
                    <p>{{pageData?.scheduleAnAppointment?.subhead}}</p>
                  </div>
                  <div class='text-content'>
                    <app-contentful-rich-text *ngIf="pageData?.scheduleAnAppointment?.content" [document]="pageData?.scheduleAnAppointment?.content?.json"></app-contentful-rich-text>
                  </div>
                  <div class="button-container">
                    <button class="btn primary-button" (click)="triggerYCBM()">Schedule Now</button>
                    <div class="link-container" *ngIf="pageData?.scheduleAnAppointment?.linksCollection?.items?.length">
                      <ng-container *ngFor="let button of pageData.scheduleAnAppointment.linksCollection.items">
                        <app-link *ngIf="button.linkTitle && (button.internalLink?.url || button.externalLink || button.documentLink)" [childClass]="'btn primary-button'"
                                  [internalLink]="button.internalLink?.url" [linkTitle]="button.linkTitle" [target]="button.linkTarget"
                                  [externalLink]="button.externalLink" [documentLink]="button.documentLink"></app-link>
                      </ng-container>
                    </div>
                    <button class="btn secondary-button" id="ycbm-skip-now" (click)="skipYCBM()">Skip</button>
                  </div>
                </div>
                <div class="no-ycbm-intro" *ngIf="!isSkip && !showBookingButton">
                  <h2 class="header h2" *ngIf="pageData?.suppressionThankYou?.header">{{pageData?.suppressionThankYou?.header}}</h2>
                  <div class="subheader-text" *ngIf="pageData?.suppressionThankYou?.subhead">
                    <p>{{pageData?.suppressionThankYou?.subhead}}</p>
                  </div>
                  <div class='text-content'>
                    <app-contentful-rich-text *ngIf="pageData?.suppressionThankYou?.content" [document]="pageData?.suppressionThankYou?.content?.json"></app-contentful-rich-text>
                  </div>
                  <div class="button-container">
                    <div class="link-container" *ngIf="pageData?.suppressionThankYou?.linksCollection?.items?.length">
                      <ng-container *ngFor="let button of pageData.suppressionThankYou.linksCollection.items">
                        <app-link *ngIf="button.linkTitle && (button.internalLink?.url || button.externalLink || button.documentLink)" [childClass]="'btn primary-button'"
                                  [internalLink]="button.internalLink?.url" [linkTitle]="button.linkTitle" [target]="button.linkTarget"
                                  [externalLink]="button.externalLink" [documentLink]="button.documentLink"></app-link>
                      </ng-container>
                    </div>
                  </div>
                </div>
                <div class="skip-intro" *ngIf="isSkip">
                  <h2 class="header h2" *ngIf="pageData?.thankYou?.header">{{pageData?.thankYou?.header}}</h2>
                  <div class="subheader-text" *ngIf="pageData?.thankYou?.subhead">
                    <p>{{pageData?.thankYou?.subhead}}</p>
                  </div>
                  <div class='text-content'>
                    <app-contentful-rich-text *ngIf="pageData?.thankYou?.content" [document]="pageData?.thankYou?.content?.json"></app-contentful-rich-text>
                  </div>
                  <div class="button-container">
                    <div class="link-container" *ngIf="pageData?.thankYou?.linksCollection?.items?.length">
                      <ng-container *ngFor="let button of pageData.thankYou.linksCollection?.items">
                        <app-link *ngIf="button.linkTitle && (button.internalLink?.url || button.externalLink || button.documentLink)" [childClass]="'btn primary-button'"
                                  [internalLink]="button.internalLink?.url" [linkTitle]="button.linkTitle" [target]="button.linkTarget"
                                  [externalLink]="button.externalLink" [documentLink]="button.documentLink"></app-link>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
    </div>
</section>

<section *ngIf="ycbmSubmitted">
  <div class='grid-container'>
    <div class='grid-x' id="booking-content">
      <div id="iframeLoader" class="iframeLoader"></div>
    </div>
  </div>
</section>

<div class='divider' *ngIf="faqAccordion?.topDivider">
  <div class="grid-container">
    <div class="line-break"></div>
  </div>
</div>
<section *ngIf="faqAccordion" class="faq-accordion {{backgroundColor}}">
  <div class='grid-container'>
    <div class='grid-x'>
      <div class='small-12 grid-x faq-right-container' *ngIf="hasFAQItems || hasRightRailContent">
        <div class='small-12 medium-12 large-auto left-column'>
          <ng-container *ngIf="sectionHeader || sectionSubheader">
            <div class="header-content">
              <app-h-tag *ngIf="sectionHeader" [hTag]="faqAccordion.headerTag" [content]="sectionHeader" [className]="'header h2'"></app-h-tag>
              <div class='subheader-content' *ngIf="sectionSubheader">
                <app-contentful-rich-text [document]="sectionSubheader"></app-contentful-rich-text>
              </div>
            </div>
          </ng-container>
          <div class="faq-container" *ngIf="hasFAQItems">
            <ng-container *ngFor="let faqItem of faqItems; let i=index">
              <div class="faq-content" [ngClass]="backgroundColor == 'Grey' ? 'White' : 'Grey'">
                <div class="header-content" *ngIf="faqItem.header || faqItem.introCopy?.json">
                  <app-h-tag *ngIf="faqItem.header" [hTag]="faqItem.hTag" [content]="faqItem.header" [className]="'header h3'"></app-h-tag>
                  <div class='subheader-content' *ngIf="faqItem.introCopy?.json">
                    <app-contentful-rich-text [document]="faqItem.introCopy?.json"></app-contentful-rich-text>
                  </div>
                </div>
                <cdk-accordion class="faq-items" *ngIf="faqItem.faqBlocksCollection?.items?.length">
                  <cdk-accordion-item *ngFor="let faq of faqItem.faqBlocksCollection.items; let y=index"
                                      #accordionItem="cdkAccordionItem"
                                      class="accordion-item"
                                      [ngClass]="{'active': accordionItem.expanded}">
                    <a class="accordion-title" tabindex="0" role="button"
                       [attr.id]="'accordion-header-' + i + y"
                       [attr.aria-expanded]="accordionItem.expanded"
                       [attr.aria-controls]="'accordion-body-' + i + y"
                       (click)="accordionItem.toggle()"
                       (keypress)="accordionItem.toggle()">
                      {{ faq.question }}
                    </a>
                    <div class="accordion-content"
                         role="region"
                         [attr.id]="'accordion-body-' + i + y"
                         [attr.aria-labelledby]="'accordion-header-' + i + y">
                      <app-contentful-rich-text [document]="faq?.answer?.json"></app-contentful-rich-text>
                    </div>
                  </cdk-accordion-item>
                </cdk-accordion>
              </div>
            </ng-container>
          </div>
          <div *ngIf="faqAccordion?.disclaimer">
            <app-disclaimer-item [disclaimerItem]="faqAccordion?.disclaimer"></app-disclaimer-item>
          </div>
        </div>
        <div class='small-12 medium-12 large-4 right-column' *ngIf="hasRightRailContent">
          <div class="right-rail">
            <ng-container *ngFor="let rrItem of rightRailItems">
              <div class="right-rail-box {{rrItem.backgroundColor}}" [ngClass]="{'image-only' : rrItem.imageOnly}">
                <app-content-block [headTagClass]="'header h3'" [showMedia]="true" [contentBlock]="rrItem"></app-content-block>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

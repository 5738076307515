import { gql } from "apollo-angular";
import { LINK_FIELDS } from "./link";

const FOOTER_CTA_FIELDS =  gql`
fragment FOOTER_CTA_FIELDS on FooterCta {
    topDivider
    backgroundColor
    headerTag
    header
    alignment
    mainContent {
      json
    }
    linksCollection(limit:6) {
      items {
        ... LINK_FIELDS
      }
    }
  }
${LINK_FIELDS}`

export {FOOTER_CTA_FIELDS}
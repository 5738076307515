<div class='divider' *ngIf="verticalTabs?.topDivider">
    <div class='grid-container'>
      <div class='line-break'></div>
    </div>
</div>
<section [class]="verticalTabs?.backgroundColor" *ngIf="verticalTabs">
    <div class='vertical-tabs'>
        <div class='grid-container'>
            <div class='grid-x'>
                <div class='small-12 header-container' [ngClass]="headerOnly" *ngIf="verticalTabs.headerTag || verticalTabs?.introCopy">
                    <app-h-tag [hTag]="verticalTabs.headerTag" [className]="'header h2'" [content]="verticalTabs.header" *ngIf="verticalTabs.headerTag"></app-h-tag>
                    <app-contentful-rich-text *ngIf="verticalTabs?.introCopy" [document]="verticalTabs?.introCopy?.json"></app-contentful-rich-text>
                </div>
                <div class='small-12'>
                    <div class='save-wrapper {{wrapperBackgroundColor}}'>
                        <div class='grid-x content-tab'>
                            <div class='small-12 large-3 nogutter-right'>
                                <div class='info' *ngIf="hasTabbedItems">
                                    <ng-container *ngFor="
                                        let item of verticalTabs.tabbedContentCollection?.items;
                                        let i = index"
                                    >
                                        <button class="tab-link" 
                                            [ngClass]="{ active: i === (activeTab == null ? 0 : activeTab)  || item === (activeTab === null ? 0 : activeTab) }"
                                            (click)="(activeTab = i)"
                                        >
                                            <span>{{ item.header }}</span>
                                        </button>
                                    </ng-container>
                                    
                                </div>
                            </div>
                            <div class='small-12 large-auto nogutter-left'>
                                <div class='info-stage tabbed-content desktop {{tabbedBackgroundColor}}' role='tabpanel'>
                                    <ng-container *ngFor="
                                        let item of verticalTabs.tabbedContentCollection?.items;
                                        let i = index"
                                    >
                                        <div [ngClass]="{ active: i === (activeTab === null ? 0 : activeTab)  || item === (activeTab === null ? 0 : activeTab) }"
                                            *ngIf="i === (activeTab === null ? 0 : activeTab)  || item === (activeTab === null ? 0 : activeTab)"
                                        >
                                            <app-content-block [headTagClass]="'header h3'" [showMedia]="true" [contentBlock]="item"></app-content-block>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <cdk-accordion class="content-accordion" *ngIf="hasTabbedItems">
                            <cdk-accordion-item *ngFor="let item of verticalTabs.tabbedContentCollection?.items; let i=index"
                                [ngClass]="{'active' : accordionItem.expanded }"    
                                #accordionItem="cdkAccordionItem" class="accordion-item"
                                [ngClass]="{'active' : i === activeTab || item === activeTab }">
                                <a class="accordion-title" tabindex="0" role="button"
                                    [attr.id]="'accordion-header-' + i"
                                    [attr.aria-expanded]="accordionItem.expanded"
                                    [attr.aria-controls]="'accordion-body-' + i"
                                    (click)="accordionItem.toggle(); activeTab == i ? (activeTab = null) : (activeTab = i)"
                                    (keypress)="accordionItem.toggle(); activeTab == i ? (activeTab = null) : (activeTab = i)">
                                    {{ item.header }}
                                </a>
                                <div class="accordion-content {{wrapperBackgroundColor}}"
                                    role="region"
                                    [attr.id]="'accordion-body-' + i"
                                    [attr.aria-labelledby]="'accordion-header-' + i">
                                    <app-content-block [headTagClass]="'header h3'" [showMedia]="true" [contentBlock]="item"></app-content-block>
                                </div>
                            </cdk-accordion-item>
                        </cdk-accordion>
                    </div>
                </div>
                <div class='small-12'>
                    <div class='extra-callout {{wrapperBackgroundColor}}'>
                        <ng-container *ngFor="
                            let item of verticalTabs.rightColumnItemListCollection?.items;
                            let i = index"
                        >
                            <app-content-block [headTagClass]="'header h3'" [showMedia]="true" [contentBlock]="item"></app-content-block>
                        </ng-container>
                    </div>
                </div>
                <app-disclaimer-item *ngIf="verticalTabs.disclaimer as disclaimer" [disclaimerItem]="disclaimer"></app-disclaimer-item>
            </div>
        </div>
    </div>
</section>
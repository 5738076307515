import { Component } from '@angular/core';
import { Login } from 'src/app/core/models/login';
import { JwtAuth } from 'src/app/core/models/jwtAuth';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less']
})
export class LoginComponent {
  invalidLogin: boolean = false;
  loginDto = new Login();
  jwtDto = new JwtAuth();

  constructor(private router: Router, private route: ActivatedRoute, private authService: AuthenticationService){}

  login(loginDto: Login){
    if (!loginDto.username || !loginDto.password) {     
      // Perform client-side validation; ensure both fields are filled.
      this.invalidLogin = true;
      return;
    }

    this.authService.login(loginDto).subscribe({
      next: (jwtDto) => {
        if(jwtDto && jwtDto.result) {
          this.invalidLogin = false;
          localStorage.setItem('jwtToken', jwtDto.token);
          let returnUrl = this.route.snapshot.queryParamMap.get('returnUrl');
          returnUrl = returnUrl && returnUrl.length > 0 ? returnUrl : "/";
          this.router.navigate([returnUrl]);
        } else {
          // Handle the case where the server response is missing the token.
          this.invalidLogin = true;
        }
        
      },
      error: (error) => {
        // Handle HTTP errors, e.g., server not reachable or other issues.
        this.invalidLogin = true;
        console.error('An error occurred:', error);
      }
    });

  }
}

<div class='divider' *ngIf="multiColumn?.topDivider">
  <div class="grid-container">
    <div class="line-break"></div>
  </div>
</div>
<section *ngIf="multiColumn" class="multicolumn {{backgroundColor}}">
  <div class='grid-container'>
    <div class='grid-x'>
      <ng-container *ngIf="sectionHeader || sectionSubheader">
        <div class="small-12 header-content" [class]="headerAlignmentClass">
          <app-h-tag *ngIf="sectionHeader" [hTag]="multiColumn.headerTag" [content]="sectionHeader" [className]="'header h2'"></app-h-tag>
          <div class='subheader-content' *ngIf="sectionSubheader">
            <app-contentful-rich-text [document]="sectionSubheader"></app-contentful-rich-text>
          </div>
        </div>
      </ng-container>
      <div class='small-12 grid-x multicolumn-container {{multiColumnType}}' *ngIf="hasColumns" [ngClass]="{'multiple-columns': totalColumns > 1, 'one-column': totalColumns == 1}">
        <ng-container *ngFor="let column of columns; let i=index">
          <div class="column-item small-12 large-auto col-{{i + 1}} {{backgroundColor}}" [ngClass]="{'medium-6': totalColumns > 1 && !(totalColumns == 3 && i == 2) }">
            <div class="col-container">
              <div class="column-content-body" [class]="contentAlignmentClass">
                <app-content-block [headTagClass]="'header h3'" [buttonClass]="'btn primary-button'" [showMedia]="true" [contentBlock]="column"></app-content-block>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="small-12" [class]="headerAlignmentClass" *ngIf="multiColumn?.disclaimer">
        <app-disclaimer-item [disclaimerItem]="multiColumn?.disclaimer"></app-disclaimer-item>
      </div>
    </div> 
  </div>
</section>

import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { SlideInOutAnimation } from './animations';

@Component({
  selector: 'app-notes-and-conditions',
  templateUrl: './notes-and-conditions.component.html',
  styleUrls: ['./notes-and-conditions.component.less'],
  animations: [SlideInOutAnimation],
  encapsulation: ViewEncapsulation.None
})
export class NotesAndConditionsComponent implements OnInit{
  @Input({ required: true }) public notesAndConditions!: any;
  toggleButton: boolean = false;
  backgroundColor:string = '';

  animationState = 'out';

  ngOnInit(): void {
    this.toggleShowNotes('notesContent');
    this.backgroundColor = this.notesAndConditions?.backgroundColor != '' ? this.notesAndConditions.backgroundColor : "White";
  }

  toggleShowNotes(className: string) {
    if (className === 'notesContent') {
      this.animationState = this.animationState === 'out' ? 'in' : 'out';
      this.toggleButton = !this.toggleButton;
    }
  }
  
}

<section class='divider' [class]="contentBlock.backgroundColor" *ngIf="contentBlock?.topDivider">
    <div class="grid-container">
        <div class="line-break"></div>
    </div>
</section>
<section [class]="contentBlock.backgroundColor">
    <div class="content-block-rr">
        <div class="grid-container">
            <div class="grid-x content-block-rr-container">
                <div class="small-12 medium-12 large-auto content-section">
                    <div class="content-wrapper" *ngIf="contentBlock.contentBlockCollection?.items as contentItems">
                        <ng-container *ngFor="let contentItem of contentItems">
                            <app-content-block [contentBlock]="contentItem" [showMedia]="true"></app-content-block>
                        </ng-container>
                        <app-disclaimer-item *ngIf="contentBlock.disclaimer as disclaimer" [disclaimerItem]="disclaimer"></app-disclaimer-item>
                    </div>
                </div>
                <div class="small-12 medium-12 large-4 right-section" *ngIf="rightRailBoxes.length > 0">
                    <div class="right-rail">
                        <ng-container *ngFor="let rightRailBox of rightRailBoxes">
                            <div [class]="rightRailBox.backgroundColor" [ngClass]="{'image-only' : rightRailBox.imageOnly}">
                                <app-content-block [headTagClass]="'header h3'" [showMedia]="true" [contentBlock]="rightRailBox"></app-content-block>
                                <app-disclaimer-item *ngIf="contentBlock.disclaimer as disclaimer" [disclaimerItem]="disclaimer"></app-disclaimer-item>
                            </div>
                        </ng-container>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>
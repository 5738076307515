import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ContentfulService } from 'src/app/core/services/contentful.service';
import { GET_QUOTES } from './quotes-query';

@Component({
  selector: 'app-quotes',
  templateUrl: './quotes.component.html',
  styleUrls: ['./quotes.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class QuotesComponent implements OnInit{
  @Input({ required: true }) public quotesID!: any;
  quotes: any;
  backgroundColor: string = '';

  constructor (
    private contentfulService: ContentfulService
  ) {}

  ngOnInit(): void {
    this.getQuotesData();
  }

  slideConfig = {
    "dots": true,
    "infinite": true,
    "slidesToShow": 1,
    "arrows": true,
    "slidesToScroll": 1,
    "adaptiveHeight": true,
    "accessibility": false,
  };

  getQuotesData() {
    try {
      this.contentfulService.getPageData(GET_QUOTES, {ID: this.quotesID.sys.id}).subscribe(res => {
        if (res?.data?.quotes) {
          this.quotes = res?.data?.quotes;
          this.backgroundColor = this.quotes?.backgroundColor != '' ? this.quotes.backgroundColor : "White";
        }
      })
    } catch (e) {
      console.error("failed gql query for quotes")
    }
  }

}

import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Constants } from 'src/app/config/app.config';

@Injectable({
  providedIn: 'root'
})
export class PreviewModeService {

    private previewMode = new BehaviorSubject<boolean>(false);

    constructor(@Inject(DOCUMENT) private dom: Document, @Optional() private constants: Constants ){ }

    setPreviewMode() {
        console.log('dom', this.dom.URL);
        const url = new URL(this.dom.URL)
        if (url.searchParams.has('preview') && url.searchParams.get('preview') === this.constants.ContentFulPreviewSecret) {
            this.previewMode.next(true);
        }
        return new Promise((resolve, reject) => resolve(true));
    }
    
    getValue() {
        return this.previewMode.asObservable();
    }

    isPreviewMode() : boolean {
        return this.previewMode.getValue();
    }

    exitPreviewMode() {
        this.previewMode.next(false);
    }
    
  
}

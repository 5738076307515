
<section class='divider' [class]="twoColumn.backgroundColor" *ngIf="twoColumn?.topDivider">
    <div class="grid-container">
        <div class="line-break"></div>
    </div>
</section>
<section [class]="twoColumn.backgroundColor">
    <div class="large-two-column">
        <div class="grid-container">
            <app-h-tag [ngClass]="[mainContentAlignment]" *ngIf="twoColumn.header" [hTag]="twoColumn.headerTag" [content]="twoColumn.header"
                [className]="'header h2'"></app-h-tag>
            <app-contentful-rich-text class="main-content" [ngClass]="[mainContentAlignment]" [document]="twoColumn?.introCopy?.json"></app-contentful-rich-text>
            <div class="grid-x">
                <div class="small-12 medium-12 large-6 column-one" *ngIf="twoColumn.column1Content">
                    <div class="column-content" [ngClass]="[columnAlignment]">
                        <app-content-block [showMedia]="true" [contentBlock]="twoColumn.column1Content"></app-content-block>
                        <app-disclaimer-item *ngIf="twoColumn.column1Content.disclaimer as disclaimer" [disclaimerItem]="disclaimer"></app-disclaimer-item>
                    </div>
                </div>
                <div class="small-12 medium-12 large-6 column-two" *ngIf="twoColumn.column2Content">
                    <div class="column-content" [ngClass]="[columnAlignment]">
                        <app-content-block [showMedia]="true" [contentBlock]="twoColumn.column2Content"></app-content-block>
                        <app-disclaimer-item *ngIf="twoColumn.column1Content.disclaimer as disclaimer" [disclaimerItem]="disclaimer"></app-disclaimer-item>
                    </div>
                </div>
                <div class="small-12" [ngClass]="[mainContentAlignment]">
                    <app-disclaimer-item *ngIf="twoColumn.disclaimer as disclaimer" [disclaimerItem]="disclaimer"></app-disclaimer-item>
                </div>
            </div>
        </div>
    </div>
</section>
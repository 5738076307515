import { gql } from "apollo-angular";
import { CONTENT_BLOCK_FIELDS } from "./content-block"
import { DISCLAIMER_ITEM_FIELDS } from "./disclaimer-item"

export const BIG_PHOTO_FIELDS = gql`
  fragment BIG_PHOTO_FIELDS on BigPhoto {
    title
    imageOverlay
    backgroundColor
    textPositionVertical
    textPositionHorizontal
    horizontalPosition
    verticalPosition
    backgroundImage {
      url
      sys {
        id
      }
    }
    disclaimer {
      ... DISCLAIMER_ITEM_FIELDS
    }
    copy {
      ... CONTENT_BLOCK_FIELDS
    }
  }
 ${CONTENT_BLOCK_FIELDS}
 ${DISCLAIMER_ITEM_FIELDS}`;

import { Directive, HostListener, ElementRef, Input } from '@angular/core'

@Directive({
  selector: '[mobMask]'
})
export class MobDirective {

  constructor(private _el: ElementRef) { }
  @Input() mobMask: boolean = true;
  @HostListener('input', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    const input = event.target as HTMLInputElement;

    const initalValue = this._el.nativeElement.value;
    this._el.nativeElement.value = initalValue.replace(/[^0-9]*/g, '');
    if (initalValue !== this._el.nativeElement.value) {
      event.stopPropagation();
    }
    if (this.mobMask) {
      let trimmed = input.value.replace(/\s+/g, '');

      if (trimmed.length > 12) {
        trimmed = trimmed.substr(0, 12);
      }
  
  
      trimmed = trimmed.replace(/-/g, '');
  
      let numbers = [];
  
      numbers.push(trimmed.substr(0, 3));
      if (trimmed.substr(3, 2) !== "")
        numbers.push(trimmed.substr(3, 3));
      if (trimmed.substr(6, 3) != "")
        numbers.push(trimmed.substr(6, 4));
  
  
      input.value = numbers.join('-');
    }
  }
}
import {Injectable} from '@angular/core';

import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  private display: BehaviorSubject<'open' | 'close'> = new BehaviorSubject<'open' | 'close'>('close');

  watch(): Observable<'open' | 'close'> {
    return this.display.asObservable();
  }

  open() {
    document.body.style.position = 'fixed';
    document.body.style.top = `-${window.scrollY}px`;
    this.display.next('open');
  }

  close() {
    document.body.style.position = '';
    document.body.style.top = '';
    this.display.next('close');
  }
}
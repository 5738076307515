import { gql } from "apollo-angular";
import { CONTENT_BLOCK_FULL_FIELDS } from "./content-block-full"
import { DISCLAIMER_ITEM_FIELDS } from "./disclaimer-item"

export const FAQ_ACCORDION_FIELDS = gql`
  fragment FAQ_ACCORDION_FIELDS on FaqAccordion {
    topDivider
    backgroundColor
    header
    headerTag
    mainContent {
      json
    }
    faqListCollection (limit: 10) {
      items {
        header,
        hTag
        introCopy {
          json
        }
        faqBlocksCollection (limit: 20) {
            items {
              question
              answer {
                json
              }
            }
        }
      }
    }
    rightRailContentCollection (limit: 6) {
      items {
        ...CONTENT_BLOCK_FULL_FIELDS
      }
    }
    disclaimer {
      ... DISCLAIMER_ITEM_FIELDS
    }
  }
 ${DISCLAIMER_ITEM_FIELDS}
 ${CONTENT_BLOCK_FULL_FIELDS}`;

import { Component, Input, Inject, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-multi-column',
  templateUrl: './multi-column.component.html',
  styleUrls: ['./multi-column.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class MultiColumnComponent implements OnInit {
  @Input({ required: true }) public multiColumn!: any;

  backgroundColor: string = '';
  columns: Array<any> = [];
  contentAlignmentClass: string = '';
  headerAlignmentClass: string = ''; 
  hasColumns: Boolean = true;
  totalColumns: number = 0;
  multiColumnType: string = 'Basic';
  sectionHeader: string = '';
  sectionSubheader: string = ''

  constructor() {
  }

  ngOnInit(): void {
    this.backgroundColor = this.getBackgroundColorClass(this.multiColumn?.backgroundColor);
    this.contentAlignmentClass = this.getTextAlignmentClass(this.multiColumn?.columnAlignment);
    this.headerAlignmentClass = this.getTextAlignmentClass(this.multiColumn?.headerAlignment);
    this.hasColumns = this.multiColumn?.columnsCollection?.items?.length;
    this.sectionHeader = this.multiColumn?.header;
    this.sectionSubheader = this.multiColumn?.mainContent?.json;

    if (this.hasColumns) {
      this.columns = JSON.parse(JSON.stringify(this.multiColumn.columnsCollection.items));
      this.totalColumns = this.columns.length;
      this.multiColumnType = this.multiColumn.columnType;
    }
  }

  getTextAlignmentClass(alignmentValue: string): string {
    let alignment = alignmentValue ? alignmentValue.toLowerCase() : 'left';
    return `text-${alignment}`;
  }

  getBackgroundColorClass(bckgrd: string): string {
    return bckgrd ? bckgrd : "White";
  }
}

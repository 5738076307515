<section class="content-block">
  <ng-container *ngIf="showMedia && isMediaPresent">
    <div class="image-wrapper">
      <ng-container *ngIf="contentBlock.faIcon; else firstElse">
        <i [ngClass]="{'fa fal':!contentBlock.faIcon.includes('fab')}" [class]="contentBlock.faIcon" aria-hidden="true"></i>
      </ng-container>
      <ng-template #firstElse>
        <ng-container *ngIf="contentBlock.image?.url; else video">
          <img loading="lazy" [src]="contentBlock.image?.url" [alt]="contentBlock.image?.description">
        </ng-container>
        <ng-template #video>
          <ng-container *ngIf="contentBlock.video?.url; else youtube">
            <video controls [src]='contentBlock.video?.url' id='content-block-video'></video>
          </ng-container>
          <ng-template #youtube>
            <div class="video-player">
              <a *ngIf="contentBlock.videoYouTubeLink" class="video-link" [href]="videoSrc" data-lity>
                  <div class="videoOverlay"></div>    
                  <img loading="lazy" [src]="thumbnailSrc">
              </a>
            </div>
        </ng-template>
        </ng-template>
      </ng-template>

    </div>
  </ng-container>
  <div class="content-wrapper" *ngIf="contentBlock?.header || contentBlock?.statIcon">
    <div class="stat-container" *ngIf="contentBlock?.statIcon">
      {{contentBlock?.statIcon}}
    </div>
    <app-h-tag *ngIf="contentBlock?.header" [hTag]="contentBlock.headerTag" [content]="contentBlock.header"
               [className]="headTagClass"></app-h-tag>
  </div>
  <div class="text-content-wrapper">
    <app-contentful-rich-text [document]="contentBlock?.mainContent?.json"></app-contentful-rich-text>
    <app-link *ngIf="contentBlock?.buttonTitle && (contentBlock?.buttonLink2?.url || contentBlock?.buttonLink || contentBlock?.documentLink)"
    [childClass]="buttonClass"
    [internalLink]="contentBlock?.buttonLink2?.url"
    [linkTitle]="contentBlock?.buttonTitle"
    [target]="contentBlock?.linkTarget"
    [externalLink]="contentBlock?.buttonLink"
    [documentLink]="contentBlock?.documentLink">
</app-link>
  <div class="link-container" *ngIf="contentBlock.rightRailLinksCollection?.items.length > 0">
    <ul class="list-group">
      <ng-container *ngFor="let link of contentBlock.rightRailLinksCollection.items">
      <li>
        <app-link *ngIf="link.linkTitle && (link.internalLink?.url || link.externalLink || link.documentLink)"
                  [childClass]="'list-link'"
                  [internalLink]="link.internalLink?.url"
                  [target]="link.linkTarget"
                  [externalLink]="link.externalLink"
                  [documentLink]="link.documentLink"
                  [linkContent]="linkContent"
                  [linkTitle]="link.linkTitle">
        </app-link>
      </li>
      <ng-template #linkContent>
        <span>{{ link.linkTitle }}</span>
      </ng-template>
      </ng-container>
    </ul>
  </div>
</div>
  
</section>

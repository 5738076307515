import { gql } from "apollo-angular";
import { DISCLAIMER_ITEM_FIELDS } from "./disclaimer-item"

export const START_DATES_FIELDS = gql`
  fragment START_DATES_FIELDS on StartDates {
    title
    topDivider
    header
    headerTag
    backgroundColor 
    programId
    startDateItemsCollection {
      items {
        ...on StartDateItems {
            calendarDate
            startDateDescriptor
        }
      }
    }
    introCopy {
      json
    }
    disclaimer {
      ... DISCLAIMER_ITEM_FIELDS
    }
  }
${DISCLAIMER_ITEM_FIELDS}`;

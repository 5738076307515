import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';
import { Constants } from 'src/app/config/app.config';
import { CommonService } from './common.service';


@Injectable({
  providedIn: 'root'
})
export class SEOService {

    readonly metaConst: MetaTags = {
        "description": "",
        "og:title": "",
        "og:description": "",
        "og:site_name": "",
        "og:url": "",
        "og:image": "",
        "og:type": "Web Site",
        "robots": "",
    }
    metaTags: MetaTags;

    constructor(@Inject(DOCUMENT) private dom: Document,
    private metaTagService: Meta,
    private title: Title,
    private commonService: CommonService,
    private constant:Constants) {
        this.metaTags = this.metaConst;
    }

    /**
     * To add the canonical url to the dom
     * @param url canonical url from CMS
     */
    addOrUpdateCanonicalURL(canonical: string, url: string) {
        const canURL = canonical ? canonical : this.constant.domain + url;
        if(this.commonService.isPlatformBrowser()) {
            const link: HTMLLinkElement | null = this.dom.querySelector('link[rel="canonical"]');
            if (link) {
                link.setAttribute('href', canURL);
            }
        } else {
            const link: HTMLLinkElement = this.dom.createElement('link');
            link.setAttribute('rel', 'canonical');
            link.setAttribute('href', canURL);
            this.dom.head.appendChild(link);
        }
    }

    /**
     * To set the page title
     * @param newTitle title from CMS
     */
    setTitle(newTitle: string)  {
        this.title.setTitle(newTitle);
    }

    /**
     * Returns the current page title
     * @returns 
     */
    getTitle() {
        return this.title.getTitle();
    }

    /**
     * Add the standard meta tags to the DOM
     */
    addOrUpdateMetaTags() {
        const tags = this.formatMetaTagsData();
        if(this.commonService.isPlatformBrowser()) {
            tags.forEach(tag => {
                this.metaTagService.updateTag(tag)
            });
        } else {
            this.metaTagService.addTags(tags);
            // resets the metaTags variable
            this.metaTags = this.metaConst;
        }
        
    }

    /**
     * Sets the robots meta tag
     */
    setRobots(value: string[] ) {
        value = [...value];
        if (!value || value.length == 0) return;
        this.metaTags.robots = value.reverse().join(', ').toLowerCase();
    }

    /**
     * Format the data from CMS to the type MetaDefinition for passing into meta service
     * @returns 
     */
    private formatMetaTagsData(): MetaDefinition [] {
        const tags: MetaDefinition [] = [];
        for( const tag in this.metaTags) {
            const prop = tag as keyof MetaTags;
            if (Object.hasOwn(this.metaTags, tag) && (this.metaTags[prop] != null && this.metaTags[prop] != undefined)) {
                tags.push({name: tag, content: this.metaTags[prop]})
            }
        }
        return tags;
    }
  
}

interface MetaTags {
    "description": string,
    "og:title": string,
    "og:description": string,
    "og:site_name": string,
    "og:url": string,
    "og:image": string,
    "og:type": string,
    "robots": string,
}

<div class='divider' *ngIf="startDates?.topDivider">
  <div class="grid-container">
    <div class="line-break"></div>
  </div>
</div>
<section class="start-dates {{backgroundColor}}" *ngIf="startDates">
  <div class='grid-container'>
    <div class='grid-x'>
      <ng-container *ngIf="sectionHeader || sectionSubheader">
        <div class="small-12 header-content">
          <app-h-tag *ngIf="sectionHeader" [hTag]="startDates.headerTag" [content]="sectionHeader" [className]="'header h2'"></app-h-tag>
          <div class='subheader-content' *ngIf="sectionSubheader">
            <app-contentful-rich-text [document]="sectionSubheader"></app-contentful-rich-text>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="startDateItemsArray.length">
        <div class='small-12 start-dates-slider'>
          <ngx-slick-carousel class="carousel" id="slick-carousel" #slickModal="slick-carousel" [config]="slideConfig">
            <ng-container *ngFor="let sDate of startDateItemsArray">
              <div ngxSlickItem class="slide">
                <div class='start-dates-wrapper'>
                  <div class="year">{{sDate.date?.getFullYear()}}</div>
                  <div class="date">{{sDate.monthName}} {{sDate.date?.getUTCDate()}}</div>
                  <div class="term">{{sDate.dateDescription}}</div>
                </div>
              </div>
            </ng-container>
          </ngx-slick-carousel>
        </div>
      </ng-container>
    </div>
    <app-disclaimer-item *ngIf="startDates?.disclaimer" [disclaimerItem]="startDates?.disclaimer"></app-disclaimer-item>
  </div>
</section>

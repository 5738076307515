import { gql } from "apollo-angular";

export const  LINK_FIELDS = gql`
  fragment LINK_FIELDS on Link {
    internalLink {
        ... on OptimizedCombo{
            url
        }
        ... on RequestInformationPageTemplate {
          url
        }
    }
    linkTitle
    documentLink {
        url
    }
    linkTarget
    externalLink
    chatLinkCheckBox
  }`;
<section class='divider' [class]="horizontalTabs.backgroundColor" *ngIf="horizontalTabs?.topDivider">
    <div class="grid-container">
        <div class="line-break"></div>
    </div>
</section>
<section [class]="horizontalTabs.backgroundColor">
    <div class="horizontal-tabs">
        <div class="grid-container">
            <div class="grid-x">
				<div class="small-12 header-container" [ngClass]="headerOnly" *ngIf="horizontalTabs.headerTag || horizontalTabs?.introCopy">
					<app-h-tag [hTag]="horizontalTabs.headerTag" [className]="'header h2'" [content]="horizontalTabs.header" *ngIf="horizontalTabs.headerTag"></app-h-tag>
					<div class="text-content" *ngIf="horizontalTabs?.introCopy">
						<app-contentful-rich-text [document]="horizontalTabs?.introCopy?.json"></app-contentful-rich-text>
					</div>
				</div>
				<div class="small-12 content-tab" *ngIf="hasTabbedItems">
					<div class="tabs">
						<button class="tab-link" *ngFor="let item of tabbedItems; let i = index" 
						[ngClass]="{ active: i === (activeTab == null ? 0 : activeTab)  || item === (activeTab === null ? 0 : activeTab) }" 
                            (click)="(activeTab = i)">
							{{item.header}}
						</button>
					</div>
				</div>
				<div class="small-12 content-tab" *ngIf="hasTabbedItems">
					<ng-container *ngFor="let item of tabbedItems; let i = index">
						<div class="" [ngClass]="{ active: i === (activeTab == null ? 0 : activeTab)  || item === (activeTab === null ? 0 : activeTab) }"
							*ngIf="i === (activeTab === null ? 0 : activeTab)  || item === (activeTab === null ? 0 : activeTab)"
						>
							<ng-container [ngSwitch]="item.__typename">
								<ng-container *ngSwitchCase="ComponentType.ContentBlock"><div class="tabbed-content-block {{wrapperBackgroundColor}}"><app-content-block [headTagClass]="'header h3'" [showMedia]="true" [contentBlock]="item"></app-content-block></div></ng-container>
								<ng-container *ngSwitchCase="ComponentType.ContentBlockRR"><div class="tabbed-content-block-rr {{wrapperBackgroundColor}}"><app-horizontal-tabbed-content [horizontalTabbedContent]="item" ></app-horizontal-tabbed-content></div></ng-container>
								<ng-container *ngSwitchDefault></ng-container>
							</ng-container>
						</div>
					</ng-container>
				</div>
                <div class="small-12 content-accordion" *ngIf="hasTabbedItems">
                    <cdk-accordion>
                        <cdk-accordion-item *ngFor="let item of tabbedItems; let i = index"
                            [ngClass]="{'active' : accordionItem.expanded }"
                            #accordionItem="cdkAccordionItem" class="accordion-item"
                            [ngClass]="{'active' : i === activeTab || item === activeTab }">
                            <a class="accordion-title" tabindex="0" role="button"
                                [attr.id]="'accordion-header-' + i"
                                [attr.aria-expanded]="accordionItem.expanded"
                                [attr.aria-controls]="'accordion-body-' + i"
                                (click)="accordionItem.toggle(); (activeTab == i ? (activeTab = null) : (activeTab = i))"
                                (keypress)="accordionItem.toggle(); (activeTab == i ? (activeTab = null) : (activeTab = i))">
                                {{ item.header }}
                            </a>
                            <div class="accordion-content"
                                role="region"
                                [attr.id]="'accordion-body-' + i"
                                [attr.aria-labelledby]="'accordion-header-' + i">
                                <ng-container [ngSwitch]="item.__typename">
                                    <ng-container *ngSwitchCase="ComponentType.ContentBlock"><div class="tabbed-content-block {{wrapperBackgroundColor}}"><app-content-block [headTagClass]="'header h3'" [showMedia]="true" [contentBlock]="item"></app-content-block></div></ng-container>
                                    <ng-container *ngSwitchCase="ComponentType.ContentBlockRR"><div class="tabbed-content-block-rr {{wrapperBackgroundColor}}"><app-horizontal-tabbed-content [horizontalTabbedContent]="item" ></app-horizontal-tabbed-content></div></ng-container>
                                    <ng-container *ngSwitchDefault></ng-container>
                                </ng-container>
                            </div>
                        </cdk-accordion-item>
                    </cdk-accordion>
                </div>
				<app-disclaimer-item *ngIf="horizontalTabs?.disclaimer" [disclaimerItem]="horizontalTabs?.disclaimer"></app-disclaimer-item>
            </div>
  	    </div>
    </div>
</section>
import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-hero-sif',
  templateUrl: './hero-sif.component.html',
  styleUrls: ['./hero-sif.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class HeroSifComponent{
  @Input()  heroSif!: any;
}

import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { CommonService } from '../../core/services/common.service';

@Component({
  selector: 'app-vertical-tabs',
  templateUrl: './vertical-tabs.component.html',
  styleUrls: ['./vertical-tabs.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class VerticalTabsComponent implements OnInit {
  @Input({ required: true }) public verticalTabs!: any;
  activeTab: any;
  wrapperBackgroundColor: string = '';
  headerOnly: string = '';
  tabbedBackgroundColor: string = '';
  hasTabbedItems: boolean = false;
  tabbedItems: Array<any> = [];

  constructor(public commonService: CommonService) {

  }
  
  ngOnInit(): void {
    this.wrapperBackgroundColor = this.verticalTabs?.backgroundColor == "White" ? "Grey" : "White";
    this.tabbedBackgroundColor = this.wrapperBackgroundColor == "Grey" ? "White" : "Grey";
    this.headerOnly = this.verticalTabs.headerTag && !this.verticalTabs?.introCopy ? "headerOnly" : "";
    this.hasTabbedItems = this.verticalTabs?.tabbedContentCollection?.items?.length;
    if (this.hasTabbedItems) {
      this.tabbedItems = JSON.parse(JSON.stringify(this.verticalTabs.tabbedContentCollection.items));
      if (this.tabbedItems?.length > 0) {
        this.activeTab = 0;
      }
    }
  }
}

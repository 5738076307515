import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, Inject, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-start-dates',
  templateUrl: './start-dates.component.html',
  styleUrls: ['./start-dates.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class StartDatesComponent implements OnInit, AfterViewInit {
  @Input({ required: true }) public startDates!: any;

  backgroundColor: string = '';
  sectionHeader: string = '';
  sectionSubheader: string = '';
  startDateItemsArray: Array<StartDateItem> = [];

  constructor(
    @Inject(DOCUMENT) private dom: Document) {
  }

  ngOnInit(): void {
    this.backgroundColor = this.getBackgroundColorClass(this.startDates?.backgroundColor);
    this.sectionHeader = this.startDates?.header;
    this.sectionSubheader = this.startDates?.introCopy?.json;
    
    if (this.startDates?.startDateItemsCollection?.items?.length) {
      this.getStartDateItems();
    }
  }

  ngAfterViewInit(): void {
    const activeSlick = Array.from(this.dom.getElementsByClassName('slick-active') as HTMLCollectionOf<HTMLElement>);
    activeSlick.forEach(element => {
      element.tabIndex = -1;
    });
  }

  slideConfig = {
    "infinite": true,
    "slidesToShow": 3,
    "arrows": true,
    "slidesToScroll": 3,
    "adaptiveHeight": true,
    "responsive": [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  getStartDateItems() {
    try {
      let datesJson = JSON.parse(JSON.stringify(this.startDates?.startDateItemsCollection?.items));

      // Coverts start dates data into an array of StartDateItem.
      datesJson.map((item: any) => {
        let date = new Date(item.calendarDate.replace(/-/g, '\/').replace(/T.+/, ''));
        let weekFuture = Date.now() + 1000 * 60 * 60 * 24 * 7;

        if (date.getTime() > weekFuture) {
          this.startDateItemsArray.push(new StartDateItem(date, item.startDateDescriptor));
        }
      });

      // Sorts the dates.
      if (this.startDateItemsArray.length) {
        this.sortArrayByDate();
      }
    } catch (e) {
      console.error("Error getting the start date items.")
    }
  }

  sortArrayByDate() {
    this.startDateItemsArray.sort((a, b) =>
      a.date === b.date ? 0 : (a.date > b.date ? 1 : -1)     
    );
  }

  getBackgroundColorClass(bckgrd: string): string {
    return bckgrd ? bckgrd : "White";
  }
}

export class StartDateItem {
  date: Date | any;
  monthName: string = '';
  dateDescription: string = '';

  constructor(date: Date, dateDescription: string) {
    this.date = date;
    this.monthName = this.getMonthName(date);
    this.dateDescription = dateDescription;
  }

  getMonthName(date: Date): string {
    let months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    return months[date.getUTCMonth()];
  }
}

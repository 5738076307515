import { AfterViewInit, Component } from '@angular/core';
import { GeoLocationService } from './core/services/geolocation.service';
import { CommonService } from './core/services/common.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent implements AfterViewInit{
  constructor(private geoLocation: GeoLocationService, private commonService: CommonService) {
  }
  ngAfterViewInit(): void {
    if(this.commonService.isPlatformBrowser()) {
      this.geoLocation.setLocation();
    }
  }
}

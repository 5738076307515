import { gql } from "apollo-angular";

export const  FOOTER_FIELDS = gql`
  fragment FOOTER_FIELDS on WebsiteFooter {
    copyright {
      json
    }
    cookieLink
    linksCollection (limit:4) {
      items {
        linkTitle
        externalLink
        internalLink {
          ...on OptimizedCombo {
            url
          }
        }
        documentLink {
          url
        }
        linkTarget
      }
    }
  }`;

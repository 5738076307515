import { DOCUMENT } from '@angular/common';
import { Directive, HostListener, Inject } from '@angular/core';
import { Router } from '@angular/router';
@Directive({
  selector: '[appInnerHtml]'
})
export class InternalLinkRoutingDirective {
  constructor(private router: Router, @Inject(DOCUMENT) private dom: Document,) {
  }

  @HostListener('click', ['$event'])
  onClick(e: any) {
    const path = e.composedPath() as Array<any>;
    const firstAnchor = path.find(p => p.tagName?.toLowerCase() === 'a');
    if (firstAnchor && !firstAnchor.hasAttribute('routerlink')) {
      const href = firstAnchor.getAttribute('href');
      const target = firstAnchor.getAttribute('target');
      
      if(href.startsWith('mailto:') || href.startsWith('tel:')) {
        return;
      } else if(href.startsWith('http') || target == '_blank') {
        const url = href.startsWith('http') ? href : this.dom.baseURI + (href.startsWith('/') ? href.slice(1,href.length) : href);
        window.open(url, "_blank");
        e.preventDefault();
      } else if(href.startsWith('#')) {
        // check for url fragments for anchor scrolling.
        let url = this.router.url;
        // removes the existing fragment from url.
        url = url.includes("#") ? url.split('#')[0] : url;
        this.router.navigateByUrl( url + href)
        e.preventDefault();
      } else {
        this.router.navigateByUrl(href);
        e.preventDefault();
      }
    }
  }
}

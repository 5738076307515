import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';


@Component({
  selector: 'app-large-two-column',
  templateUrl: './large-two-column.component.html',
  styleUrls: ['./large-two-column.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class LargeTwoColumnComponent implements OnInit{
  @Input({ required: true }) public twoColumn: any = {};
  columnAlignment: string = '';
  mainContentAlignment: string =''

  ngOnInit(): void {
    this.mainContentAlignment = this.twoColumn.headerAlignment?.toLowerCase() == 'center' ? 'text-center' : '';
    this.columnAlignment = this.twoColumn.columnAlignment?.toLowerCase() == 'center' ? 'text-center' : '';
  }

}

import { Component, Input, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { CommonService } from 'src/app/core/services/common.service';
import { ChatService } from 'src/app/core/services/chat.service';

@Component({
  selector: 'app-footer-cta',
  templateUrl: './footer-cta.component.html',
  styleUrls: ['./footer-cta.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class FooterCtaComponent implements OnInit, OnDestroy {
  @Input({ required: true }) public footerCta!: any;
  isChatLoaded = false;
  chatLoadedEvent: any;

  constructor(public commonService: CommonService, private chatService: ChatService) { 
    
  }

  ngOnInit(): void {
    this.chatLoadedEvent = this.chatService.chatWidgetLoaded$.subscribe((data) => {
      this.isChatLoaded = data;
    })
  }

  ngOnDestroy(): void {
    if (this.chatLoadedEvent) {
      this.chatLoadedEvent.unsubscribe();
    }
  }
}

import { gql } from "apollo-angular";
import { CONTENT_BLOCK_FULL_FIELDS } from "./content-block-full"
import { CONTENT_BLOCK_RR_FIELDS } from "./content-block-rr";
import { DISCLAIMER_ITEM_FIELDS } from "./disclaimer-item";

export const HORIZONTAL_TAB_FIELDS = gql`
fragment HORIZONTAL_TAB_FIELDS on HorizontalTabs {
  backgroundColor
  header
  headerTag
  title
  topDivider
  introCopy {
    json
  }
  tabbedContentCollection (limit:4) {
    items {
      ...CONTENT_BLOCK_FULL_FIELDS
      ...CONTENT_BLOCK_RR_FIELDS
    }
  }
  disclaimer{
    ...DISCLAIMER_ITEM_FIELDS
  }
}
${CONTENT_BLOCK_FULL_FIELDS}
${CONTENT_BLOCK_RR_FIELDS}
${DISCLAIMER_ITEM_FIELDS}`;
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ApolloModule } from 'apollo-angular';
import { InternalLinkRoutingDirective } from './directives/internal-link-routing.directive';
import { ClickOutsideDirective } from './directives/click-outtside.directive';
import { HttpClientModule } from '@angular/common/http';
import { MobDirective } from './directives/phone-mask.directive';
import { NumberDirective } from './directives/numbers-only.directive';
import { InputRefDirective } from './directives/input-ref.directive';
import { RecaptchaModule, RECAPTCHA_SETTINGS, RecaptchaSettings, RecaptchaFormsModule, RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';

import { environment } from 'src/environments/environment';

const RECAPTCHA_CHALLENGE_KEY = environment.Recaptcha.ChallengePublicKey;
const RECAPTCHA_HIDDEN_KEY = environment.Recaptcha.PublicKey;

@NgModule({
  declarations: [
    InternalLinkRoutingDirective,
    InputRefDirective,
    ClickOutsideDirective,
    MobDirective,
    NumberDirective
  ],
  imports: [
    CommonModule,
    RouterModule,
    ApolloModule,
    HttpClientModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    RecaptchaV3Module
  ],
  providers: [
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: RECAPTCHA_HIDDEN_KEY },
    { provide: RECAPTCHA_SETTINGS, useValue: { siteKey: RECAPTCHA_CHALLENGE_KEY } as RecaptchaSettings }
  ],
  exports: [ CommonModule, RouterModule,
    InternalLinkRoutingDirective,
    ClickOutsideDirective,
    InputRefDirective,
    HttpClientModule,
    MobDirective,
    NumberDirective,
    RecaptchaModule,
    RecaptchaFormsModule,
    RecaptchaV3Module
  ]
})
export class CoreModule { }

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Constants, Environments } from 'src/app/config/app.config';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private jwtHelper: JwtHelperService, private constants: Constants) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let isAllowed = false;
    switch (this.constants.environmentName) {
      case Environments.Development:
        isAllowed = true;
        break;
      case Environments.Stage:
        isAllowed = this.validateToken(state);
        break;
      case Environments.Production:
        if (route.data && !route.data['login']) {
          isAllowed = true;
        } else {
          isAllowed = this.validateToken(state);
        }
        break;
    }
    return isAllowed;

  }

  validateToken(state: RouterStateSnapshot): boolean {
    let url = state.url.split("?")[0];
    if (typeof localStorage !== 'undefined') {
      const token = localStorage.getItem("jwtToken");
      const isAllowed = (token && !this.jwtHelper.isTokenExpired(token));
      if (isAllowed) {
        return true;
      } else {
        this.router.navigate(["login"], { queryParams: { returnUrl: url } });
        return false
      }
    } else {
      return false;
    }
  }
}

<section>
    <div class="horizontal-tabbed-content">
        <div class="grid-container tabbed-container">
            <div class="grid-x">
                <div class="small-12 medium-12 large-8 content-section">
                    <div class="content-wrapper" *ngIf="horizontalTabbedContent.contentBlockCollection?.items as contentItems">
                        <ng-container *ngFor="let contentItem of contentItems">
                            <app-content-block [headTagClass]="'header h3'" [contentBlock]="contentItem"></app-content-block>
                        </ng-container>
                    </div>
                </div>
                <div class="small-12 medium-12 large-4 right-section" *ngIf="rightRailBoxes.length > 0">
                    <div class="right-rail">
                        <ng-container *ngFor="let rightRailBox of rightRailBoxes">
                            <div [class]="rightRailBox.backgroundColor" [ngClass]="{'image-only' : rightRailBox.imageOnly}">
                                <app-content-block [headTagClass]="'header h4'" [showMedia]="true" [contentBlock]="rightRailBox"></app-content-block>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
import { gql } from "apollo-angular";
import { LINK_FIELDS } from "./link";

export const  CONTENT_BLOCK_FULL_FIELDS = gql`
  fragment CONTENT_BLOCK_FULL_FIELDS on ContentBlock {
    topDivider
    backgroundColor
    header
    headerTag
    mainContent {
      json
    }
    faIcon
    statIcon
    imageAlignment
    image {
      url
      description
    }
    video {
      url
      description
    }
    
    videoYouTubeLink
    disclaimer {
      disclaimer {
        json
      }
    }

    buttonTitle
    buttonLink
    linkTarget
    buttonLink2 {
      ... on OptimizedCombo {
        url
      } 
    }
    rightRailLinksCollection (limit:4) {
      items {
        ...LINK_FIELDS
      }
    }
}
${LINK_FIELDS}`;

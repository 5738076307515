export const environment = {
  production: false,
  link: 'https://onlinedegrees-stage-umassglobal.mkt.kaplan.com/',
  name: 'stage environment',
  Contentful: {
    BasePath: 'https://graphql.contentful.com/content/v1/spaces/',
    Space: 'dgtm7q88gt4d',
    AccessToken: 'yZkGby49thZgqn10F52W2H9tU4jWcZtoavo60GMff8A',
    PreviewToken: 'fZCNMIhprkCc71KKZke_shZzdKyI146QJUhnqqnpWbk',
    environment: ''
  },
  Genesys: {
    src: 'https://storage.googleapis.com/kna-gen-widgets-stg/lib/cxbus.min.js',
    pluginsPath: 'https://storage.googleapis.com/kna-gen-widgets-stg/lib/plugins/',
    loadFile: 'https://storage.googleapis.com/kna-gen-widgets-stg/revolution.widgets.min.js'
  },
  Ycbm: {
    src: 'https://embed.youcanbook.me',
    path: 'umassglobal-stage'
  },
  APIBasePath: '',
  Recaptcha: {
    PublicKey: '6LdHUoooAAAAAK_DcYSMGfRJG-1vgL8BAVyI3K60',
    ChallengePublicKey: '6Ld00oYoAAAAADGetXe_WtFoq923LTIaHI-RI2bY'
  }
};

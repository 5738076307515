import { DOCUMENT, Location } from '@angular/common';
import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { Constants } from 'src/app/config/app.config';

@Injectable({
    providedIn: 'root'
})
export class QueryStringService {

    constructor(@Inject(DOCUMENT) private dom: Document) {
        this.dom.location.search
    }

  getParameter(name: string, persistCasing: boolean = false, url?: string) {
		if (!url) url = this.dom.location.href || this.dom.URL;
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)', 'i');
    const results = persistCasing ? regex.exec(url) : regex.exec(url.toLowerCase());

		if (!results) return null;
		if (!results[2]) return '';
		return decodeURIComponent(results[2].replace(/\+/g, ' '));
	}
  
}

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router, Event, NavigationEnd, ActivatedRoute} from '@angular/router';
import { ContentfulService } from '../../core/services/contentful.service';
import { GET_OC_Collection } from './optimized-combo-query';
import { Constants, ComponentType } from '../../config/app.config';
import { SEOService } from '../../core/services/seo.service';
import { DOCUMENT, Location } from '@angular/common';
import { HeaderFooterService } from 'src/app/core/services/header-footer.service';

@Component({
  selector: 'app-optimized-combo',
  templateUrl: './optimized-combo.component.html',
  styleUrls: ['./optimized-combo.component.less'],
})
export class OptimizedComboComponent implements OnInit, OnDestroy {

  public pageData: any;
  loading = false;
  error: any;
  currentRoute: any;
  routerEvent: any;
  ComponentType = ComponentType;
  noRoutesConfigured = false;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private contentfulService: ContentfulService,
    public constant: Constants,
    private headerService: HeaderFooterService,
    @Inject(DOCUMENT) private dom: Document,
    private seo: SEOService,
    private location: Location) {

  }

  ngOnInit(): void {
    this.currentRoute = this.router.url;
    this.getPageData();
    this.listenRouterEvents();  
  }

  ngOnDestroy(): void {
    this.routerEvent.unsubscribe();
  }

  /**
   * Listen for route change and fetches page data based on the slug
   */
  private listenRouterEvents() {
    this.routerEvent = this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
          this.currentRoute = event.url;
          this.getPageData();
      }
  });
  }

  /**
   * Method to get the page data based on the url fragment
   */
  private getPageData() {
    try {
      this.contentfulService.getPageData(GET_OC_Collection, {slug: this.parseSlug(this.currentRoute)}).subscribe(res => {
        if (res?.data?.optimizedComboCollection?.items?.length > 0) {
          this.pageData = res?.data?.optimizedComboCollection.items[0]
          console.log("rr sif",this.pageData);
          this.setSEOFields();
          this.checkForHeaderFooterOverrides();
        }
        else  {
          this.router.navigateByUrl('404')
          this.pageData = null;
        }
      });
    } catch (e) {
      console.error("failed gql query")
    }
  }

  parseSlug(url: string): string {
    // trim the '/' at the beginning
    let slug = url.slice(1, url.length);
    // remove query string params from slug
    if (slug.split('?')[0] === '') {
      slug = this.constant.DefaultHomePage;
    }
    return slug
  }

  /**
   * Sanitizing query string params 
   */
  sanitizeQSP(){
    let queryParams : any;
    let hash = '';
    this.route.queryParams.subscribe(params => {
      queryParams = params;
    });
    if(Object.keys(queryParams).length > 0){
      const qsp = JSON.parse(JSON.stringify(queryParams))
      const values = Object.values(qsp);
      values.map((item: any, index: any) =>{
        if(item.includes(' and ') || item.includes(' or ')){
          values[index] = '';
        }
      });
      Object.keys(qsp).forEach((k, i) => {
        qsp[k] = values[i];
      });
      const baseRoute = this.currentRoute.split('?')[0];
      if(this.currentRoute.includes('#')){
        hash = this.currentRoute.split('#')[1];
        this.currentRoute = baseRoute + '?'+ Object.keys(qsp).map(key => `${key}=${qsp[key]}`).join("&") + '#' + hash; 
      } else {
        this.currentRoute = baseRoute + '?'+ Object.keys(qsp).map(key => `${key}=${qsp[key]}`).join("&"); 
      }
      this.location.replaceState(this.currentRoute.slice(1, this.currentRoute.length))
    }
  }

  /**
   * Gets the data from CMS fields and set to seo service for creating meta tags.
   */
  setSEOFields() {
    this.sanitizeQSP();
    this.seo.setTitle(this.pageData.pageTitleExternal);
    this.seo.setRobots(this.pageData.robotsTag);
    this.seo.metaTags.description = this.pageData.metaDescription || '';
    this.seo.metaTags['og:title'] = this.pageData.pageTitleExternal;
    this.seo.metaTags['og:description'] = this.pageData.metaDescription || '';
    this.seo.metaTags['og:site_name'] = this.pageData.metaOgSite;
    this.seo.metaTags['og:url'] = this.constant.domain + this.currentRoute.slice(1, this.currentRoute.length);
    this.seo.metaTags['og:image'] = this.pageData.metaOgImage?.url || '';
    this.seo.addOrUpdateMetaTags();
    this.seo.addOrUpdateCanonicalURL(this.pageData.canonical, this.currentRoute.slice(1, this.currentRoute.length));
  }

  checkForHeaderFooterOverrides() {
    // check for header override
    const header = this.pageData.overrideHeader ? this.pageData.overrideHeader : false;
    this.headerService.headerConfiguration.next(header);
    // check for footer override
    const footer = this.pageData.overrideFooter ? this.pageData.overrideFooter : false;
    this.headerService.footerConfiguration.next(footer);
  }

}


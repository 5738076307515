import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { CommonService } from '../../core/services/common.service';

@Component({
  selector: 'app-horizontal-callout',
  templateUrl: './horizontal-callout.component.html',
  styleUrls: ['./horizontal-callout.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class HorizontalCalloutComponent implements OnInit {
  @Input({ required: true }) public horizontalCallout!: any;
  isImageVisible: boolean = false;
  isfaIconVisible: boolean = false;
  backgroundColor: string = '';
  noImageAlignmet: string = '';
  constructor(public commonService: CommonService) {

  }

  ngOnInit(): void {
    this.isImageVisible = this.horizontalCallout.image == null ? false : true;
    this.isfaIconVisible = this.horizontalCallout.faIcon == null ? false : true;
    this.backgroundColor = this.horizontalCallout?.backgroundColor;
    this.noImageAlignmet = !this.isImageVisible ? "no-img-alignment" : "";
  }

}

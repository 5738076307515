import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Constants } from 'src/app/config/app.config';
import ycbmStateRestrictionsData from 'src/assets/data/ycbm-state-restricctions.json';

@Injectable({
  providedIn: 'root'
})
export class YcbmService {

  constructor(@Inject(DOCUMENT) private dom: Document, private constants: Constants) {  }

  showYCBMForm(bookingUrl:any, sifData:any, leadImportID:any) {
    try {
      this.appendBookingSchedule(bookingUrl, sifData, leadImportID);
    } catch (e: any) {
      console.log(e.message);
    }
  }

  showYCBMButton(stateCode: string, sifData: any): boolean {
    try {
      stateCode = stateCode ? stateCode.toUpperCase() : '';
      let degree = sifData.degree ? sifData.degree.toLowerCase() : '';
      let militaryValue = sifData.military ? sifData.military.toLowerCase() : '';
      let isDomestic = sifData.isDomestic == "true";

      if ((!isDomestic && militaryValue == 'no') || (isDomestic && this.isStateCodeRestricted(stateCode, degree, militaryValue))) {
        return false;
      } else {
        return true;
      }
    } catch (e) {
      return false;
    }
  }

  appendBookingSchedule(partialIframeUrl:any, sifData:any, leadImportID:any) {
    // Create YCBM script.
    let script = document.createElement('script');
    script.async = true;
    script.setAttribute('src', this.constants.YcbmSrc);
    script.setAttribute('data-domain', this.constants.YcbmPath);
    script.setAttribute('data-autoscroll', '');
	
    // YCBM data can be passed adding the data-selections attribute to the script. Example: data-selections='{"form": {"FNAME": "fName", "EMAIL": "email@mmm.com"}}';
    // However, this workaround is used instead because using the data-selections would not work. The json file passed would throw error when parsing it.
    script.onload = () => {
      let iframe = <HTMLElement>this.dom.querySelector('#booking-content iframe');
      let iframeSrc = iframe.getAttribute("src");
      iframe.setAttribute("src", iframeSrc + this.getIframeQueryStringParams(partialIframeUrl, sifData, leadImportID));
      let iframLoderSelector = <HTMLElement>this.dom.getElementById('iframeLoader');
      iframLoderSelector.style.display = 'none';
    }

	  // Append YCBM script to DOM.
    let ycbmContetSelector = <HTMLElement>this.dom.getElementById('booking-content');
    ycbmContetSelector.appendChild(script);
  }

  getCommonQueryStringParams(sifData:any) {
    // From QS.
    let utmTerm = sifData.utmTerm;
    let utmCampaign = sifData.utmCampaign;
    let utmSource = sifData.utmSource;
    let utmMedium = sifData.utmMedium;
    let utmContent = sifData.utmContent;

    let iframeQSPs = "&GACLIENTID=" + sifData.gaClientID +
    "&GATRACKID=" + sifData.gatrackid +
    "&SOURCE=" + sifData.source +
    "&VENDOR=" + sifData.vendor +  
    "&GAUSERID=" + sifData.gauserid +
    (utmSource != null && utmSource != "" ? "&UTMSOURCE=" + utmSource : "") +
    (utmMedium != null && utmMedium != "" ? "&UTMMEDIUM=" + utmMedium : "") +
    (utmCampaign != null && utmCampaign != "" ? "&UTMCAMPAIGN=" + utmCampaign : "") +
    (utmTerm != null && utmTerm != "" ? "&UTMTERM=" + utmTerm : "") +
    (utmContent != null && utmContent != "" ? "&UTMCONTENT=" + utmContent : "");

    return iframeQSPs;
  }

  getIframeQueryStringParams(partialIframeUrl:any, sifData:any, leadImportID:any) {
    let queryStringParams = "";
    let commonQSPs = this.getCommonQueryStringParams(sifData);
    let leadImportIDExists = leadImportID != '' && leadImportID != "0";

    if (partialIframeUrl == "") {
      let phone = sifData.isDomestic == "true" ? "1" + sifData.phone : sifData.phone;
      let zip = sifData.zip;
      queryStringParams = "&FNAME=" + sifData.firstName +
        "&LNAME=" + sifData.lastName +
        "&EMAIL=" + sifData.sifEmail +
        "&PHONE=" + ("%2B" + phone) +
        "&PROGRAM=" + sifData.program +
        "&APPSOURCE=WEB" +
        (zip != "00000" ? "&ZIP=" + zip : "") +
        (leadImportIDExists ? "&LEADIMPORTID=" + leadImportID : "") +
        commonQSPs;
    } else {
      let qsp = partialIframeUrl.split("?")[1];
      if (qsp.indexOf("LEADIMPORTID=") == -1 && leadImportIDExists) {
        qsp = qsp + "&LEADIMPORTID=" + leadImportID;
      }

      queryStringParams = "&" + qsp.replace("+", "%2B") + commonQSPs;
    }

    return queryStringParams;
  }

  private isStateCodeRestricted(stateCode: string, degree: string, militaryValue: string) {
    return ycbmStateRestrictionsData?.states?.indexOf(stateCode) != -1 || this.isStateCodeRestrictedByDegree(stateCode, degree) || this.isStateCodeRestrictedByMilitary(stateCode, militaryValue);
  }

  private isStateCodeRestrictedByDegree(stateCode: string, degree: string): boolean {
    let element = ycbmStateRestrictionsData?.degree?.find((item: any) => item.degreeCode == degree && item.states.indexOf(stateCode) != -1);
    return element ? true : false;
  }

  private isStateCodeRestrictedByMilitary(stateCode: string, militaryValue: string): boolean {
    let element = ycbmStateRestrictionsData?.military?.find((item: any) => item.military == militaryValue && item.states.indexOf(stateCode) != -1);
    return element ? true : false;
  }
}

import { gql } from "apollo-angular";

export const HORIZONTAL_CALLOUT_FIELDS = gql`
  fragment HORIZONTAL_CALLOUT_FIELDS on HorizontalCallout {
    topDivider
    backgroundColor
    header
    hTag
    image {
      url
    }
    faIcon
    mainContent {
      json
    }
    linkTitle
    linkTarget
    internalLink {
      ... on OptimizedCombo {
        url
      }
    }
    externalLink
}`;
<section class='divider' [class]="contentBlock.backgroundColor" *ngIf="contentBlock?.topDivider">
    <div class="grid-container">
        <div class="line-break"></div>
    </div>
</section>
<section [class]="contentBlock.backgroundColor">
    <div class="content-block-full">
        <div class="grid-container">
            <div class="grid-x" [ngClass]="{'image-left': isImageLeftAligned}" >
                
                <div class="small-12 medium-12 content-section" [ngClass]="{'large-6': isMediaPresent, 'full-width': !isMediaPresent}">
                    <div class="content-wrapper-full">
                        <app-content-block [contentBlock]="contentBlock" [showMedia]="contentBlock?.faIcon"></app-content-block>
                        <app-disclaimer-item *ngIf="contentBlock.disclaimer as disclaimer" [disclaimerItem]="disclaimer"></app-disclaimer-item>
                    </div>
                </div>
                <div *ngIf="isMediaPresent" class="small-12 medium-12 image-section"  [ngClass]="{'large-6': isMediaPresent}" >
                    <div class="image-wrapper">
                        <ng-container *ngIf="contentBlock.image?.url; else video">
                            <img loading="lazy" [src]="contentBlock.image?.url" [alt]="contentBlock.image?.description">
                        </ng-container>
                        <ng-template #video>
                            <ng-container *ngIf="contentBlock.video?.url; else youtube">
                                <video controls [src]='contentBlock.video?.url' id='content-block-video'></video>
                            </ng-container>
                            <ng-template #youtube>
                                <div class="video-player">
                                    <a *ngIf="contentBlock.videoYouTubeLink" [href]="videoSrc" data-lity>
                                        <div class="videoOverlay"></div>    
                                        <img loading="lazy" [src]="thumbnailSrc">
                                    </a>
                                </div>
                            </ng-template>
                        </ng-template>
                        
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>
import { gql } from "apollo-angular";

export const  CONTENT_BLOCK_FIELDS = gql`
  fragment CONTENT_BLOCK_FIELDS on ContentBlock {
    header
    headerTag
    rightRailLinksCollection (limit:4) {
      items {
        ...LINK_FIELDS
      }
    }
    faIcon
    statIcon
    buttonTitle
    buttonLink
    linkTarget
    buttonLink2 {
      ... on OptimizedCombo {
        url
      } 
    }
    mainContent {
        json
    }
}`;

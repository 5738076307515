import { Injectable, InjectionToken } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class Constants {
    EncryptKey: string;
    countryCode: string;
    APIBasePath: string;
    APIBasePathContentFul: string;
    ContentFulAccessToken: string;
    ContentFulPreviewToken: string;
    APIRequestTimeout: number;
    ContentFulPreviewSecret: string;
    DefaultSiteConfigEntry: string;
    GenesysSrc: string
    GenesysPluginsPath: string;
    GenesysLoadFile: string;
    YcbmSrc: string;
    YcbmPath: string;
    DefaultHomePage: string;
    IpStackEndPoint: string;
    SIFSourceID: string;
    SIFVendorID: string;
    environmentName:  string;
    domain: string;
    ReCaptchaPublicKey: string;
    ReCaptchaChallengePublicKey: string;


    constructor() {
        this.EncryptKey = '##pg@law~Kap~2023##';
        this.countryCode = 'US';
        this.APIBasePath = environment.APIBasePath;
        this.APIBasePathContentFul = environment.Contentful.BasePath + environment.Contentful.Space;
        this.APIRequestTimeout = 25000000;
        this.ContentFulAccessToken = environment.Contentful.AccessToken;
        this.ContentFulPreviewToken = environment.Contentful.PreviewToken;
        this.ContentFulPreviewSecret = 'UmassGlobalPreview';
        this.DefaultSiteConfigEntry = "iKOTlZ2NaVRref6LNQTMz";
        this.GenesysSrc = environment.Genesys.src;
        this.GenesysPluginsPath = environment.Genesys.pluginsPath;
        this.GenesysLoadFile = environment.Genesys.loadFile;
        this.YcbmSrc = environment.Ycbm.src;
        this.YcbmPath = environment.Ycbm.path;
        this.DefaultHomePage = '/';
        this.IpStackEndPoint = 'https://api.ipstack.com/check?access_key=2ac4553bc5dca2b31534a6c9c341ac0c&output=json&legacy=1';
        this.SIFSourceID = "MSPSC01060";
        this.SIFVendorID = "MSPV02116";
        this.environmentName = environment.name;
        this.domain = environment.link;
        this.ReCaptchaPublicKey = environment.Recaptcha.PublicKey;
        this.ReCaptchaChallengePublicKey = environment.Recaptcha.ChallengePublicKey;
    }

}

// This value should match the content model
export enum ComponentType {
    Hero = "Hero",
    ContentBlock = "ContentBlock",
    MultiColumn = "MultiColumn",
    FooterCta = "FooterCta",
    Quotes = "Quotes",
    BigPhoto = "BigPhoto",
    HorizontalCallout = "HorizontalCallout",
    ContentBlockRR = "ContentBlockWithRightRailCallout",
    LargeTwoColumn = "Large2Column",
    StartDates = "StartDates",
    FaqAccordion = "FaqAccordion",
    HorizontalTabs = "HorizontalTabs",
    HorizontalTabsContentBlock = "HoizontalTabsContentBlock",
    VerticalTabs = "VerticalTabs",
}

export enum ImageAlignment {
    Left = "Left",
    Right = "Right"
}

export enum Environments {
    Development = "development environment",
    Stage = "stage environment",
    Production = "production environment"
}

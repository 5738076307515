<section *ngIf="bigPhoto" [ngClass]="['big-photo', backgroundColor]" >
  <div class="big-photo-backgrd" [ngStyle]="imageStyles">
    <div [ngClass]="['overlay', overlayClass, backgroundColor]"></div>
    <div class="grid-container">
      <div [ngClass]="['grid-x content-container', textVerticalPositionClass, textHorizontalPositionClass]">
        <div [ngClass]="['small-12 large-7', textAlignmentClass]">
          <app-content-block [contentBlock]="bigPhoto?.copy" [showMedia]="true"></app-content-block>
          <app-disclaimer-item *ngIf="bigPhoto?.disclaimer" [disclaimerItem]="bigPhoto?.disclaimer"></app-disclaimer-item>
        </div>
      </div>
    </div>
  </div>
</section>

import { gql } from "apollo-angular";
import { LINK_FIELDS } from "./link";

export const  CONTENT_BLOCK_RR_FIELDS = gql`
  fragment CONTENT_BLOCK_RR_FIELDS on ContentBlockWithRightRailCallout {
    topDivider
    backgroundColor
    contentBlockCollection(limit:2){
      items {
      ...CONTENT_BLOCK_FIELDS
      }
    }
    rightRailBoxesCollection(limit:6){
      items {
        ...CONTENT_BLOCK_FULL_FIELDS
      }
    }
    disclaimer {
      disclaimer {
        json
      }
    }
    
}
${LINK_FIELDS}`;

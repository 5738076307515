<div class="hero-right-rail White">
  <div class="rr-sif-container">
    <div class="header-content text-center">
      <app-h-tag *ngIf="heroSif?.header" [hTag]="heroSif?.hTag" [content]="heroSif?.header" [className]="'header'"></app-h-tag>
      <div class="body" [innerHTML]="heroSif?.content"></div>
    </div>                           
    <div class="cta-link">
      <app-link *ngIf="heroSif?.internalLink" 
                [childClass]="'btn primary-button hero-sif-btn'"
                [internalLink]="heroSif?.internalLink?.url"
                [linkTitle]="heroSif?.linkTitle"
                [target]="heroSif?.linkTarget">
      </app-link>
    </div>    
  </div>
</div>

import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, OnDestroy, ViewEncapsulation, ChangeDetectorRef, AfterViewInit, Injector } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Constants } from 'src/app/config/app.config';
import { ContentfulService } from 'src/app/core/services/contentful.service';
import { HeaderFooterService } from 'src/app/core/services/header-footer.service';
import { SEOService } from 'src/app/core/services/seo.service';
import { GET_SIF_Collection } from './request-information';
import programData from '../../../assets/data/SIFCatalog.json';
import countriesData from '../../../assets/data/countries.json';
import { SIFService } from 'src/app/core/services/sif.service';
import { GeoLocationService } from 'src/app/core/services/geolocation.service';
import { DataLayerService } from 'src/app/core/services/data-layer.service';
import { YcbmService } from 'src/app/core/services/ycbm.service';
import { UserAgentService } from 'src/app/core/services/user-agent.service';
import { ChatService } from 'src/app/core/services/chat.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { CommonService } from 'src/app/core/services/common.service';
import { QueryStringService } from '../../core/services/query-string.service';
import { CookieService } from '../../core/services/cookie.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: 'app-request-information',
  templateUrl: './request-information.component.html',
  styleUrls: ['./request-information.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class RequestInformationComponent implements OnInit, OnDestroy, AfterViewInit {
  public pageData: any;
  currentRoute: any;
  programList: any;
  selectedDegreeLevel: any;
  zipCode: any;
  leadImportId: any;
  userAgentData: any;
  sifData: any = {};

  degreeLevel: Array<any> = [];
  countryCodes: Array<string> = [];
  aos: Array<string> = [];
  programs: Array<string> = [];
  sessions: Array<string> =
    [
      "Fall 2024 Session 2 (8 Wks)",
      "Spring 2025 Session 1 (8 Wks)",
      "Spring 2025 Session 2 (8 Wks)",
      "Summer 2025 Session 1 (8 Wks)",
      "Summer 2025 Session 2 (8 Wks)"
    ];

  isDomestic: boolean = true;
  maskphone: boolean = true;
  formSubmitted = false;
  ycbmSubmitted: boolean = false;
  isSkip: boolean = false;
  isGDPR: boolean = false;
  isSubmitting: boolean =false;
  disclaimerAccepted: boolean = false;
  showBookingButton: boolean = false;
  showCountryField: boolean = false;

  requestInfoForm !: FormGroup;
  internationalValidationCompleted = false;
  
  public recaptchaLoaded = false;  

  constructor(private router: Router,
    private contentfulService: ContentfulService,
    public constant: Constants,
    private headerService: HeaderFooterService,
    @Inject(DOCUMENT) private dom: Document,
    private seo: SEOService,
    private fb: FormBuilder,
    private sifService: SIFService,
    private dataLayer: DataLayerService,
    private ycbmService: YcbmService,
    private geoLocationService: GeoLocationService,
    private route: ActivatedRoute,
    private chatService: ChatService,
    private modalService: ModalService,
    private commonService: CommonService,
    private cdr: ChangeDetectorRef,
    private userAgentService: UserAgentService,
    private queryStringHandler: QueryStringService,
    private cookieHandler: CookieService,
    private injector: Injector  ) {
  }

  ngOnInit(): void {
    this.buildForm();
    this.setURLParams();
    this.getPageData();
    this.populateDegreeLevel();
    this.setUserAgentData();
    this.setGoogleAnalyticsData();
    this.chatService.showOrHideChat('hideChatButton');   
    this.setRecaptchaValues();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.updateLinkAttribure();
    }, 2000);
    if(this.commonService.isPlatformBrowser()) {
      this.checkIfInternational();
    }    
  }

  ngOnDestroy(): void {
    this.chatService.showOrHideChat('showChatButton');
  }

  updateLinkAttribure() {
    let getLinkElement = this.dom.querySelectorAll('.disclaimer .rich-text-content a');
    getLinkElement.forEach((userItem) => {
      userItem.setAttribute("target", "_blank");
    });
  }

  setURLParams() {
    this.currentRoute = this.router.url.slice(1, this.router.url.length);
    let source = this.queryStringHandler.getParameter('source', true) || this.constant.SIFSourceID;
    let ve = this.queryStringHandler.getParameter('ve', true) || this.constant.SIFVendorID;
    let utm_source = this.queryStringHandler.getParameter('utm_source', true) || '';
    let utm_medium = this.queryStringHandler.getParameter('utm_medium', true) || '';
    let utm_campaign = this.queryStringHandler.getParameter('utm_campaign', true) || '';
    let utm_content = this.queryStringHandler.getParameter('utm_content', true) || '';
    let utm_term = this.queryStringHandler.getParameter('utm_term', true) || '';
    let loadtest = this.queryStringHandler.getParameter('loadtest', true) || '';
    let qsp = this.dom.location.search || '';
    this.requestInfoForm.get('queryString')?.setValue(qsp);
    this.requestInfoForm.get('url')?.setValue(this.dom.URL);
    this.requestInfoForm.get('source')?.setValue(source?.toUpperCase());
    this.requestInfoForm.get('vendor')?.setValue(ve?.toUpperCase());
    this.requestInfoForm.get('utmSource')?.setValue(utm_source);
    this.requestInfoForm.get('utmMedium')?.setValue(utm_medium);
    this.requestInfoForm.get('utmCampaign')?.setValue(utm_campaign);
    this.requestInfoForm.get('utmContent')?.setValue(utm_content);
    this.requestInfoForm.get('utmTerm')?.setValue(utm_term);
    this.requestInfoForm.get('loadTest')?.setValue(loadtest);
  }

  setUserAgentData() {
    this.userAgentData = this.userAgentService.getUAParams();
    if (this.userAgentData) {
      this.requestInfoForm.get('browser')?.setValue(this.userAgentData.browser?.name);
      this.requestInfoForm.get('browserVersion')?.setValue(this.userAgentData.browser?.version);
      this.requestInfoForm.get('operatingSystem')?.setValue(this.userAgentData.os?.name);
      this.requestInfoForm.get('deviceCategory')?.setValue(this.userAgentData.device?.type);
    }
  }

  setGoogleAnalyticsData() {
    const gaCookie = this.cookieHandler.getCookie('_ga') || "";
    const gaTrackingId = "G-CZV35KLL6L";
    this.requestInfoForm.get('gaCookie')?.setValue(gaCookie);
    this.requestInfoForm.get('gatrackid')?.setValue(gaTrackingId);
  }

  buildForm() {
    this.requestInfoForm = this.fb.group({
      gatrackid: [''],
      name: [''],
      firstName: ['',
        [
          Validators.required,
          Validators.pattern("^\s*[a-zA-Z]+(([',. -][a-zA-Z])?[a-zA-Z]*){1}\s*$")
        ]],
      lastName: ['',
        [
          Validators.required,
          Validators.pattern("^\s*[a-zA-Z]+(([',. -][a-zA-Z])?[a-zA-Z]*){1}\s*$")
        ]],
      address: [''],
      city: [''],
      school: [''],
      state: [''],
      zip: [''],
      phoneCountryCode: [''],
      phone: ['', Validators.required],
      country: ['',
      [
        Validators.required
      ]],
      email: [''],
      sifEmail: ['',
      [
        Validators.required,
        Validators.pattern(/^[\w-.+]+@([\w-]+\.)+[\w-]{2,4}$/)
      ]
    ],
      military: [''],
      bsDegree: [''],
      areaOfStudy: [{ value: '', disabled: true },
      [
        Validators.required
      ]],
      programName: [{ value: '', disabled: true },
      [
        Validators.required
      ]],
      program: ['',
      [
        Validators.required
      ]],
      degree: ['',
      [
        Validators.required
      ]],
      campusCode: [''],
      education: [''],
      startDate: [{ value: '', disabled: true },
      [
        Validators.required
      ]],
      url: [''],
      browser: [''],
      browserVersion: [''],
      operatingSystem: [''],
      deviceCategory: [''],
      queryString: [''],
      gdpr: [''],
      directLeadID: [''],
      source: [this.constant.SIFSourceID],
      vendor: [this.constant.SIFVendorID],
      affiliateID: [''],
      channel: [''],
      campaignID: [''],
      originationType: [''],
      gauserid: [''],
      loadTest: [''],
      gaCookie: [''],
      gaClientID: [''],
      utmSource: [''],
      utmMedium: [''],
      utmCampaign: [''],
      utmContent: [''],
      utmTerm: [''],
      hideReCaptcha: 'false',
      recaptchaChallengeSiteKey: [this.constant.ReCaptchaChallengePublicKey],
      recaptchaChallengeToken: [''],
      recaptchaSiteKey: [this.constant.ReCaptchaPublicKey],
      recaptchaToken: ['']
    });
  }

  get formControl() {
    return this.requestInfoForm.controls;
  }

  checkIfInternational() {
    this.geoLocationService.isDomestic.subscribe((data: boolean) => {
      this.isDomestic = data;
      this.showCountryField = !this.isDomestic;
      this.setZipValidators();
      this.populateCountryCode();
      this.setPhoneValidators();
      this.maskphone = this.isDomestic;
      if(this.isDomestic){
        this.disclaimerAccepted = true
      }
      this.internationalValidationCompleted = true;
      this.cdr.markForCheck();
      this.cdr.detectChanges();
    });
  }

  triggerYCBM() {
    this.ycbmSubmitted = true;
    
    this.sifService.postYCBM(this.sifData).subscribe(
      res => {
        let bookingUrl = res?.BookingAppointmentLink;
        this.ycbmService.showYCBMForm(bookingUrl, this.sifData, this.leadImportId);
      },
      error => {
        this.ycbmService.showYCBMForm('', this.sifData, this.leadImportId);
      }
    );
  }

  updateFieldValue(field: string) {
    this.requestInfoForm.get(field)?.setValue(this.requestInfoForm.get(field)?.value);
  }

  skipYCBM() {
    this.formSubmitted = true;
    this.ycbmSubmitted = false;
    this.isSkip = true;
  }

  onSubmit() {
    if (!this.requestInfoForm.valid) {
      this.requestInfoForm.markAllAsTouched();
      return;
    }
    if(this.isGDPR && !this.disclaimerAccepted) {
      this.openModal();
      return;
    }
    //Avoid double clicking
    if (this.isSubmitting) {
      return;
    }

    this.sifData = this.requestInfoForm.value;
    delete this.sifData.programName;
    this.sifData.military = this.sifData.military ? 'Yes' : 'No';  

    // Adds isDomestic value to sifData
    this.sifData.isDomestic = this.isDomestic.toString();

    // remove till here.
    this.isSubmitting = true;

    this.sifService.postSif(this.sifData).subscribe(
      res => {
        this.leadImportId = res.LeadImportNo;
        this.showBookingButton = this.ycbmService.showYCBMButton(this.zipCode?.c, this.sifData);
        this.formSubmitted = true;
        this.sifData.zip = res.HidZIP;
        this.dataLayer.GTMDataLayerPush('SIFInteractSubmit', 'Success', '', 'Submit Your Request', this.sifData, this.leadImportId);
      },
      err => {
        this.dataLayer.GTMDataLayerPush('SIFInteractSubmit', 'Fail', err.message, 'Submit Your Request', this.sifData, '');
      }
    );
  }

  /**
   * Method to get the page data based on the url fragment
   */
  private getPageData() {
    try {
      this.contentfulService.getPageData(GET_SIF_Collection, { slug: this.currentRoute }).subscribe(res => {
        if (res?.data?.requestInformationPageTemplateCollection?.items?.length > 0) {
          this.pageData = res?.data?.requestInformationPageTemplateCollection.items[0]
          // this.noRoutesConfigured = false;
          this.setSEOFields();
          this.checkForHeaderFooterOverrides();
        }
        else {
          this.router.navigateByUrl('404')
          // this.noRoutesConfigured = true;
          this.pageData = null;
        }
      })
    } catch (e) {
      console.error("failed gql query")
    }
  }

  /**
   * Gets the data from CMS fields and set to seo service for creating meta tags.
   */
  setSEOFields() {
    this.seo.setTitle(this.pageData.pageTitleExternal);
    this.seo.setRobots(this.pageData.robotsTag);
    this.seo.metaTags.description = this.pageData.metaDescription || ""
    this.seo.metaTags['og:title'] = this.pageData.pageTitleExternal
    this.seo.metaTags['og:description'] = this.pageData.metaDescription || ""
    this.seo.metaTags['og:site_name'] = this.pageData.metaOgSite
    this.seo.metaTags['og:url'] = this.constant.domain + this.currentRoute;
    this.seo.metaTags['og:image'] = this.pageData.metaOgImage?.url;
    this.seo.addOrUpdateMetaTags();
    this.seo.addOrUpdateCanonicalURL(this.pageData.canonical, this.currentRoute);
  }

  checkForHeaderFooterOverrides() {
    // check for header override
    const header = this.pageData.overrideHeader ? this.pageData.overrideHeader : false;
    this.headerService.headerConfiguration.next(header);
    // check for footer override
    const footer = this.pageData.overrideFooter ? this.pageData.overrideFooter : false;
    this.headerService.footerConfiguration.next(footer);
  }

  validateZipCode(event: any) {
    var zipcode = event.target.value;

    // The maxlength=5 and minlength=5 handldes the validation of the entered value's length.
    // To avoid calling the api multiple times, the zip code validation will be done only when the input's value is 5 digits long.
    if (zipcode.length == 5) {
      this.sifService.zipcodeValidate(zipcode).subscribe((res) => {
        this.zipCode = res;

        if (!this.zipCode.z) {
          this.requestInfoForm.get('zip')?.setErrors({ 'incorrect': true });
        } else {
          this.requestInfoForm.get('zip')?.setValue(this.zipCode.z)
          this.requestInfoForm.get('state')?.setValue(this.zipCode.n)
          this.requestInfoForm.get('city')?.setValue(this.zipCode.y)
        }
      });
    }
  }

  populateDegreeLevel() {
    this.degreeLevel = programData.levels;
    this.requestInfoForm.get('degree')?.setValue('');
  }

  changedDegreeLevel(e: any) {
    this.selectedDegreeLevel = programData.levels.find((item: any) => item.code == e.target.value);
    this.aos = this.selectedDegreeLevel.areas;
    this.requestInfoForm.get('areaOfStudy')?.enable();
    this.requestInfoForm.get('programName')?.setValue('');
    this.requestInfoForm.get('programName')?.disable();
    this.requestInfoForm.get('startDate')?.setValue('');
    this.requestInfoForm.get('startDate')?.disable();

    if(this.aos.length == 1){
      this.requestInfoForm.get('areaOfStudy')?.setValue(this.aos[0]);
      let e = {
        target: {
        value: this.aos[0]
      }}
      this.changedAOS(e)
    } else {
      this.requestInfoForm.get('areaOfStudy')?.setValue('');
    }
  }

  changedAOS(e: any) {
    this.programList = this.selectedDegreeLevel.Program[0][e.target.value];
    this.programs = this.programList.map((item: { name: any; }) => item.name);
    this.requestInfoForm.get('programName')?.enable();
    this.requestInfoForm.get('programName')?.setValue('');
    this.requestInfoForm.get('startDate')?.setValue('');
    this.requestInfoForm.get('startDate')?.disable();

    if(this.programs.length == 1){
      this.requestInfoForm.get('programName')?.setValue(this.programs[0]);
      let e = {
        target: {
        value: this.programs[0]
      }}
      this.changedProgram(e)
    } else {
      this.requestInfoForm.get('programName')?.setValue('');
    }
  }

  changedProgram(e: any) {
    const program = e.target.value;
    const programItem = this.programList.filter((item: any) => item.name == program);
    this.requestInfoForm.get('program')?.setValue(programItem[0].code);
    this.requestInfoForm.get('school')?.setValue(programItem[0].school);
    this.requestInfoForm.get('startDate')?.enable();
  }

  populateCountryCode() {
    const countryCode = countriesData.map((item: any) => item.name)
    this.countryCodes = countryCode;
   
    if (this.isDomestic) {
      this.maskphone = true;
      this.requestInfoForm.get('country')?.setValue('United States');
      this.requestInfoForm.get('phoneCountryCode')?.setValue("1");
    } else {
      this.maskphone = false;
      this.requestInfoForm.get('country')?.setValue('');
    }
  }

  changedCountryCode(e: any) {
    const country = countriesData.find((item: any) => item.name === e.target.value);
    this.requestInfoForm.get('phoneCountryCode')?.setValue("" + country['data-code']);
    const isUnitedStates = country?.name == 'United States';
    this.isDomestic = isUnitedStates;
    this.setPhoneValidators();

    // GDPR
    this.isGDPR = country.isGDPR == "Yes";
    if (!this.isGDPR) {
      this.disclaimerAccepted = true
    } else {
      this.disclaimerAccepted = false
    }
    this.requestInfoForm.get('gdpr')?.setValue(country.isGDPR);

    // Phone
    this.maskphone = this.isDomestic;
    this.maskPhone(isUnitedStates);

    // Zip code
    if(isUnitedStates){
      this.requestInfoForm.get('zip')?.setValidators([
        Validators.required,
        Validators.pattern("^[0-9]{5}$")
      ]);
    } else {
      this.requestInfoForm.get('zip')?.setErrors(null);
      this.requestInfoForm.get('phoneCountryCode')?.setValidators([
        Validators.required,  
        Validators.pattern("^[0-9]*$")])
    }
  }

  setZipValidators() {
    if (this.isDomestic) {
      this.maskphone = true;
      this.requestInfoForm.get('zip')?.setValidators([
        Validators.required,
        Validators.pattern("^[0-9]{5}$")
      ]);
    } else {
      this.maskphone = false;
      this.requestInfoForm.get('zip')?.setErrors(null)
    }
  }

  setPhoneValidators() {
    if (this.isDomestic) {
      this.requestInfoForm.get('phone')?.setValidators(
        [
          Validators.required,
          Validators.pattern("^[2-9][0-9]{2}[- ]?[0-9]{3}[- ]?([0-9]{4})$"),
          Validators.minLength(10)
        ]);
    } else {
      this.requestInfoForm.get('phone')?.setValidators(
        [
          Validators.required,
          Validators.minLength(6)
        ]);
    }
  }

  maskPhone(usphone: boolean) {
    let phone = this.requestInfoForm.get('phone')?.value;

    if (phone) {
      phone = phone.split('-').join('');

      if (phone.length < 10 && phone.length > 3) {
        let digit = [phone.slice(0, 3), '-', phone.slice(3, 6), '-', phone.slice(6)].join('');
        usphone ?
          this.requestInfoForm.get('phone')?.setValue(digit) :
          this.requestInfoForm.get('phone')?.setValue(phone);
      } else {
        let digit = phone.replace(/\D/g, '').match(/(\d{3})(\d{3})(\d{4})/);
        usphone ?
          this.requestInfoForm.get('phone')?.setValue(digit[1] + '-' + digit[2] + '-' + digit[3]) :
          this.requestInfoForm.get('phone')?.setValue(digit['input']);
      }
    }
  }

  captchaChallengeResolved(captchaResponse: any) {
    this.requestInfoForm.get('recaptchaChallengeToken')?.setValue(captchaResponse);
    this.setRecaptchaV3();
  }

  setRecaptchaValues() {
    this.recaptchaLoaded = this.showRecaptcha();      
  }

  setRecaptchaV3() {
    try {
      const recaptchaToken = this.requestInfoForm.get('recaptchaToken')?.value;
      //check if hidden recaptcha value already set
      if (recaptchaToken == "") {
        const recaptchaV3Service = this.injector.get<ReCaptchaV3Service>(ReCaptchaV3Service);
        recaptchaV3Service.execute('sifsubmit').subscribe(
          (token) => {
            this.requestInfoForm.get('recaptchaToken')?.setValue(token);
          },
          (error) => {
            console.log("Recaptcha error: ", error);
          }
        );
      }      
    } catch (e) {
      //alert(e);
    }
  }  

  //check for automation parameter
  showRecaptcha() {
    const automation = this.queryStringHandler.getParameter('automation', true) || '';   
    if (automation.toLowerCase() !== "true") { 
      return true;//Do not hide
    } else {
      return false;//Hide
    }
  }

  openModal() {
    this.modalService.open();
  }

  toggleTooltip(event: Event): void {
    let element = (event.target as Element);
    element?.querySelector('.tooltiptext')?.classList?.toggle('show');
  }
}

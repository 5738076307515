import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-faq-accordion',
  templateUrl: './faq-accordion.component.html',
  styleUrls: ['./faq-accordion.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class FaqAccordionComponent implements OnInit {
  @Input({ required: true }) public faqAccordion!: any;

  backgroundColor: string = '';
  sectionHeader: string = '';
  sectionSubheader: string = '';
  hasFAQItems: boolean = false;
  hasRightRailContent: boolean = false;
  faqItems: Array<any> = [];
  rightRailItems: Array<any> = [];

  constructor() {
  }

  ngOnInit(): void {
    try {
      this.backgroundColor = this.getBackgroundColorClass(this.faqAccordion?.backgroundColor);
      this.sectionHeader = this.faqAccordion?.header;
      this.sectionSubheader = this.faqAccordion?.mainContent?.json;
      this.hasFAQItems = this.faqAccordion?.faqListCollection?.items?.length;
      this.hasRightRailContent = this.faqAccordion.rightRailContentCollection?.items?.length;

      if (this.hasFAQItems) {
        this.faqItems = JSON.parse(JSON.stringify(this.faqAccordion.faqListCollection.items));
      }

      if (this.hasRightRailContent) {
        this.rightRailItems = JSON.parse(JSON.stringify(this.faqAccordion.rightRailContentCollection.items));
        this.setImageOnlyPropertyToRRContent();
      }
    } catch (e) {
      console.log('Error initializing the FAQ Accordions');
    }
  }

  getBackgroundColorClass(bckgrd: string): string {
    return bckgrd ? bckgrd : "White";
  }

  setImageOnlyPropertyToRRContent() {
    this.rightRailItems = this.rightRailItems.map((item: any) => {
      return { ...item, ...{ imageOnly: this.isImageOnly(item) } }
    })
  }

  isImageOnly(contentBlock: any) {
    if(contentBlock.header || contentBlock.rightRailLinksCollection.items.length > 0 || contentBlock.mainContent) {
      return false;
    }

    return true;
  }
}

import { Injectable, OnInit } from '@angular/core';
import { gql } from 'apollo-angular';
import { BehaviorSubject, Subject } from 'rxjs';
import { FOOTER_FIELDS, HEADER_FIELDS } from 'src/app/gql';
import { ContentfulService } from './contentful.service';
import { Constants } from 'src/app/config/app.config';

@Injectable({
  providedIn: 'root'
})
export class HeaderFooterService {

  query = gql`query siteConfiguration($ID: String!){
      siteConfiguration(id: $ID) {
        defaultHeader {
          ...HEADER_FIELDS
        }
        defaultFooter {
          ...FOOTER_FIELDS
        }
      }
    }
    ${HEADER_FIELDS}
    ${FOOTER_FIELDS}`

  headerConfiguration = new Subject<HeaderData | false>();
  footerConfiguration = new Subject<FooterData | false>();
  defaultHeaderData: HeaderData = {} as HeaderData;
  defaultFooterData: FooterData = {} as FooterData;


  constructor(
    private contentful: ContentfulService,
    private constants: Constants,
  ) {}

  /**
   * To get the default configuration for header and footer form contentful
   */
  getDefaultSiteConfiguration() {
    this.contentful.getPageData(this.query, { ID: this.constants.DefaultSiteConfigEntry}).subscribe(res => {
      try {
        this.defaultHeaderData = res.data.siteConfiguration.defaultHeader as HeaderData;
        this.defaultFooterData = res.data.siteConfiguration.defaultFooter as FooterData;
        this.headerConfiguration.next(this.defaultHeaderData);
        this.footerConfiguration.next(this.defaultFooterData);
      } catch (e) {
        console.error("Error while extracting data from default site configuration content", e);
      }
      
    })
  }
  
}

export interface FooterData {
  copyright: any,
  linksCollection: any,
  cookieLink: boolean
}

export interface HeaderData {
  logo: InternalLink | undefined,
  logoLink: string,
  contactUsPhoneNumber: string,
  contactUsChat: string,
  contactUsLinkTitle: string
  contactUsLink: any;
  buttonLink1: ButtonLink,
  buttonLink2: ButtonLink
}


interface ButtonLink {
  documentLink: string,
  externalLink: string,
  internalLink: InternalLink | undefined,
  linkTarget: string,
  linkTitle: string,
}

interface InternalLink {
  url :string
}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-h-tag',
  template: `
    <ng-container [ngSwitch]="hTag">
      <ng-container *ngSwitchCase="'H1'"><h1 [class]="className" >{{content}}</h1></ng-container>
      <ng-container *ngSwitchCase="'H2'"><h2 [class]="className" >{{content}}</h2></ng-container>
      <ng-container *ngSwitchCase="'H3'"><h3 [class]="className" >{{content}}</h3></ng-container>
      <ng-container *ngSwitchCase="'H4'"><h4 [class]="className" >{{content}}</h4></ng-container>
      <ng-container *ngSwitchCase="'span'"><span [class]="className" >{{content}}</span></ng-container>
      <ng-container *ngSwitchCase="'none'"><span [class]="className" >{{content}}</span></ng-container>
      <ng-container *ngSwitchDefault><h2 [class]="className" >{{content}}</h2></ng-container>
    </ng-container>
  `,
  styles: [
  ]
})
export class HTagComponent {
  @Input({required: true}) hTag: string = '';
  @Input({required: true}) content: string = '';
  @Input() className: string = '';
}

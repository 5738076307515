import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { CommonService } from 'src/app/core/services/common.service';

@Component({
  selector: 'app-horizontal-tabbed-content',
  templateUrl: './horizontal-tabbed-content.component.html',
  styleUrls: ['./horizontal-tabbed-content.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class HorizontalTabbedContentComponent implements OnInit {
  @Input({ required: true }) public horizontalTabbedContent!: any;
  rightRailBoxes: Array<any> = [];

  constructor(public commonService: CommonService) {

  }

  ngOnInit(): void {
    if (this.horizontalTabbedContent.rightRailBoxesCollection?.items?.length) {
      this.rightRailBoxes = JSON.parse(JSON.stringify(this.horizontalTabbedContent.rightRailBoxesCollection?.items));
      this.checkForImageOnlyRRBoxes()
    }
  }

  checkForImageOnlyRRBoxes() {
    this.rightRailBoxes =  this.rightRailBoxes.map((item: any) => {
      return {...item, ...{imageOnly: this.isImageOnly(item)}}
    })
  }

  isImageOnly(contentBlock: any) {
    if(contentBlock.header || 
      contentBlock.rightRailLinksCollection.items.length > 0 || 
      contentBlock.mainContent)
      {
        return false;
      }
      return true;
  }
}

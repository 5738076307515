import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OptimizedComboComponent } from './page-templates/optimized-combo/optimized-combocomponent';
import { RequestInformationComponent } from './page-templates/request-information/request-information.component';
import { ErrorPageComponent } from './page-templates/error-page/error-page.component';
import { LoginComponent } from './page-templates/login/login.component';
import { AuthGuard } from './core/guards/auth.guard';
import { PageNotFoundComponent } from './page-templates/page-not-found/page-not-found.component';

const routes: Routes = [
  {path: 'login', component: LoginComponent},
  {path:'request-information', canActivate: [AuthGuard], component: RequestInformationComponent},
  {path: 'test', canActivate: [AuthGuard], children:[{ path: '**', component: OptimizedComboComponent }], data: { login: true }},
  { path: '500', component: ErrorPageComponent, title: "UMass Global | Error Page" },
  { path: '404', canActivate: [AuthGuard], component: PageNotFoundComponent, title: "UMass Global | Page Not Found" },
  { path: '**', canActivate: [AuthGuard], component: OptimizedComboComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking',
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { gql } from "apollo-angular";
import { LINK_FIELDS } from "./link";
import { HERO_SIF_FIELDS } from "./hero-sif";

export const HERO_FIELDS = gql`
fragment HERO_FIELDS on Hero {
    title,
    heroPreheader,
    heroHeader,
    hTag,
    heroContent,
    imageOpacity,
    imageTextOverlay,
    imagePositionHorizontal,
    imagePositionVertical,
    textPositionVertical,
    textPositionHorizontal,
    disclaimer {
      ... on DisclaimerItem {
        disclaimer {
          json
        }
      }
    }
    youTubeVideoLink,
    linksCollection {
      items {
        ... LINK_FIELDS
      }
    }
    backgroundImage {
        url,
        sys {
            id
        }
    },
    heroSif {
      ... HERO_SIF_FIELDS
      
      ... on SifFullFormUMass {
        sys {
          id
        }
      }
    }
}
${LINK_FIELDS}
${HERO_SIF_FIELDS}`
<section [ngClass]="['backgroundColor']" *ngIf="notesAndConditions">
    <div class="notes-and-conditions">
        <div class="grid-container">
            <div class="grid-x">
                <div class="small-12">
                    <a class="notesLink" role="heading" aria-level="2" aria-expanded="true" tabindex="0" [ngClass]="toggleButton? 'open':'close'" (click)="toggleShowNotes('notesContent')">NOTES AND CONDITIONS</a>
                    <div class="notesContent" [@slideInOut]="animationState" *ngFor="let disclaimer of notesAndConditions">
                        <app-disclaimer-item [disclaimerItem]="disclaimer"></app-disclaimer-item>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

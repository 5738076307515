import { Component, Input, TemplateRef } from '@angular/core';
import { ChatService } from 'src/app/core/services/chat.service';

@Component({
  selector: 'app-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.less']
})
export class LinkComponent {
  @Input() public externalLink: string | null | undefined = '';
  @Input() public internalLink: string | null | undefined = '';
  @Input() public documentLink: string | null | undefined = '';
  @Input() public target: string | null | undefined = '';
  @Input({required: true}) public childClass: string ='';
  @Input({required:  true}) public linkTitle: string = '';
  @Input() public linkContent: TemplateRef <any> | null | undefined;
  @Input() public chatLink: boolean | null | undefined = false;

  constructor(private chatService: ChatService) {
  }
  
  triggerChat() {
    this.chatService.chatButtonClick.next(true);
  }
}

import { gql } from "apollo-angular";
import { CONTENT_BLOCK_FIELDS } from "./content-block";
import { DISCLAIMER_ITEM_FIELDS } from "./disclaimer-item"

export const  MULTI_COLUMN_FIELDS = gql`
  fragment MULTI_COLUMN_FIELDS on MultiColumn {
    title
    header
    headerAlignment
    headerTag
    backgroundColor
    topDivider
    columnAlignment
    columnType
    mainContent {
        json
    }
    columnsCollection (limit: 4) {
        items {
          faIcon
          image {
            url
            description
          }
          video {
            url
            description
          }
          videoYouTubeLink
          ... CONTENT_BLOCK_FIELDS  
      }
  }
  disclaimer {
    ... DISCLAIMER_ITEM_FIELDS
  }
}
${CONTENT_BLOCK_FIELDS}
${DISCLAIMER_ITEM_FIELDS}`;

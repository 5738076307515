<footer class="footer">
    <div class="site-footer">
        <div class="grid-container">
            <div class="grid-x footer-content">
                <div class="copy-right-container">
                    <app-contentful-rich-text *ngIf="footerData.copyright as copyRight" [document]="copyRight.json" [richTextContentUnsafe]="true"></app-contentful-rich-text>
                </div>
                <div class="links-container">
                  <ng-container *ngIf="footerData?.linksCollection?.items as footerLinks">
                    <app-link *ngFor="let button of footerLinks" [childClass]="'footer-link'"
                              [internalLink]="button.internalLink?.url" [linkTitle]="button.linkTitle" [target]="button.linkTarget"
                              [externalLink]="button.externalLink" [documentLink]="button.documentLink"></app-link>
                  </ng-container>
                  <a *ngIf="footerData?.cookieLink" href="javascript:Optanon.ToggleInfoDisplay()" class="footer-link">Cookie Preferences</a>
                </div>
            </div>
        </div>
    </div>
</footer>

import { Component, Input, OnInit, booleanAttribute } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { Document } from '@contentful/rich-text-types';


@Component({
  selector: 'app-contentful-rich-text',
  templateUrl: './contentful-rich-text.component.html',
  styleUrls: ['./contentful-rich-text.component.less']
})

export class ContentfulRichTextComponent implements OnInit{
  @Input({ required: true }) document!: Document | string;
  @Input({ transform: booleanAttribute }) richTextContentUnsafe  = false;
  html!: SafeHtml;

  constructor(private sanitizer: DomSanitizer){
    
  }

  ngOnInit(): void {
    if(this.richTextContentUnsafe) {
      this.html = this.getRichTextContentUnsafe(this.document)
    } else {
      this.html = this.getRichTextContent(this.document)
    }    
  }

   /**
   * To convert the json data received from contentful rich text editor to html nodes.
   * @param content json data for contentful rich text editor
   * @returns 
   */
   public getRichTextContent(content: Document | string) {
    const html = typeof content == 'string' ? content : documentToHtmlString(content);
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

    /**
   * To convert the json data received from contentful rich text editor to html nodes without sanitizer
   * @param content json data for contentful rich text editor
   * @returns 
   */
  public getRichTextContentUnsafe(content: any) {
    if(!content) return '';
    const html = typeof content == 'string' ? content : documentToHtmlString(content);
    return html.replaceAll('{{year}}', new Date().getFullYear().toString());
  }
}


<app-hero *ngIf="pageData?.hero" [hero]="pageData?.hero"></app-hero>

<div class="page-content">
    
    <!-- <ng-container *ngIf="!noRoutesConfigured" > -->
        <ng-container class="conten-area" *ngFor="let section of pageData?.contentAreaCollection.items">
          <ng-container [ngSwitch]="section.__typename">
            <ng-container *ngSwitchCase="ComponentType.ContentBlock"><app-content-block-full [contentBlock]="section"></app-content-block-full></ng-container>
            <ng-container *ngSwitchCase="ComponentType.MultiColumn"><app-multi-column [multiColumn]="section"></app-multi-column></ng-container>
            <ng-container *ngSwitchCase="ComponentType.FooterCta"><app-footer-cta [footerCta]="section"></app-footer-cta></ng-container>
            <ng-container *ngSwitchCase="ComponentType.Quotes"><app-quotes [quotesID]="section"></app-quotes></ng-container>
            <ng-container *ngSwitchCase="ComponentType.BigPhoto"><app-big-photo [bigPhoto]="section"></app-big-photo></ng-container>
            <ng-container *ngSwitchCase="ComponentType.HorizontalCallout"><app-horizontal-callout [horizontalCallout]="section"></app-horizontal-callout></ng-container>
            <ng-container *ngSwitchCase="ComponentType.ContentBlockRR"><app-content-block-right-rail [contentBlock]="section"></app-content-block-right-rail></ng-container>
            <ng-container *ngSwitchCase="ComponentType.LargeTwoColumn"><app-large-two-column [twoColumn]="section"></app-large-two-column></ng-container>
            <ng-container *ngSwitchCase="ComponentType.StartDates"><app-start-dates [startDates]="section"></app-start-dates></ng-container>
            <ng-container *ngSwitchCase="ComponentType.HorizontalTabs"><app-horizontal-tabs [horizontalTabs]="section"></app-horizontal-tabs></ng-container>
            <ng-container *ngSwitchCase="ComponentType.FaqAccordion"><app-faq-accordion [faqAccordion]="section"></app-faq-accordion></ng-container>
            <ng-container *ngSwitchCase="ComponentType.VerticalTabs"><app-vertical-tabs [verticalTabs]="section"></app-vertical-tabs></ng-container>
          </ng-container>
        </ng-container>
    <!-- </ng-container> -->
</div>

<app-notes-and-conditions *ngIf="pageData?.disclaimersCollection?.items.length > 0" [notesAndConditions]="pageData?.disclaimersCollection?.items"></app-notes-and-conditions>

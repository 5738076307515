import { gql } from "apollo-angular";
import { LINK_FIELDS } from "./link";

export const HERO_SIF_FIELDS = gql`
fragment HERO_SIF_FIELDS on HeroSif {
    header
    hTag
    content
    linkTitle
    internalLink{
      ... on RequestInformationPageTemplate {
          url
        }
    }  
}
${LINK_FIELDS}`

import { gql } from "apollo-angular";
import { LINK_FIELDS } from "./link";

export const  LARGE_TWO_COLUMN_FIELDS = gql`
  fragment LARGE_TWO_COLUMN_FIELDS on Large2Column {
    topDivider
    backgroundColor
    headerAlignment
    header
    headerTag
    introCopy {
      json
    }
    columnAlignment
    column1Content {
      ...CONTENT_BLOCK_FULL_FIELDS
    }
    column2Content {
      ...CONTENT_BLOCK_FULL_FIELDS
    }
    disclaimer {
      ... on DisclaimerItem {
        disclaimer {
          json
        }
      }
    }
}
${LINK_FIELDS}`;

<section class='divider' [class]="footerCta.backgroundColor" *ngIf="footerCta?.topDivider">
    <div class="grid-container">
      <div class="line-break"></div>
    </div>
</section>
<section [class]="footerCta.backgroundColor" *ngIf="footerCta">
    <div class="footer-cta">
        <div class="grid-container">
            <app-h-tag [hTag]="footerCta.headerTag" [className]="'header'" [class.text-center]="footerCta?.alignment?.toLowerCase() == 'center'" [content]="footerCta.header"></app-h-tag>
            <div class="text-content">
                <app-contentful-rich-text *ngIf="footerCta?.mainContent" [class.text-center]="footerCta?.alignment?.toLowerCase() == 'center'" [document]="footerCta?.mainContent?.json"></app-contentful-rich-text>
            </div>
            <div class="link-container" [class.text-center]="footerCta?.alignment?.toLowerCase() == 'center'" *ngIf="footerCta?.linksCollection?.items as footerLinks">
                <ng-container *ngFor="let button of footerLinks">
                    <app-link *ngIf="button.linkTitle && (button.internalLink?.url || button.externalLink || button.documentLink) || (button.chatLinkCheckBox && isChatLoaded)" [childClass]="'btn primary-button footer-cta-link'" 
                    [internalLink]="button.internalLink?.url" [linkTitle]="button.linkTitle" [target]="button.linkTarget" [chatLink]="button.chatLinkCheckBox"
                    [externalLink]="button.externalLink" [documentLink]="button.documentLink"></app-link>
                </ng-container>
            </div>
        </div>
    </div>
</section>
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, timeout } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SIFService {
    baseURL: string = environment.APIBasePath;
    constructor( private http: HttpClient) {}

    getData() {
        return this.http.get(this.baseURL);
    }

    postSif(sif: any): Observable<any> {
      return this.http.post(`${this.baseURL}/core/sif`, sif).pipe(timeout(40000));
    }

    zipcodeValidate(zipcode: any) {
      return this.http.get(`${this.baseURL}/core/sif/getzipcode?zipCode=${zipcode}`);
    }

    postYCBM(sif:any): Observable<any> {
      return this.http.post(`${this.baseURL}/core/ycbm`, sif);
    }
}

import {
    PathLocationStrategy,
    APP_BASE_HREF,
    PlatformLocation
  } from "@angular/common";
  import { Optional, Inject, Injectable } from "@angular/core";
  import { UrlSerializer } from "@angular/router";
  
  @Injectable()
  export class PathPreserveQueryLocationStrategy extends PathLocationStrategy {
    private get search(): string {
      return this.platformLocation?.search ?? "";
    }
    constructor(
      private platformLocation: PlatformLocation,
      private urlSerializer: UrlSerializer,
      @Optional() @Inject(APP_BASE_HREF) _baseHref?: string
    ) {
      super(platformLocation, _baseHref);
    }

    // if any qsp has to be ignored from the persisted list add it to the below list
    blackListParams = [
        'q',
        'aos',
        'zip',
        'country',
        'aoi',
        'branch',
        'skill',
        'rraos',
        'rrdeg',
        'rrpro',
        'programID',
        'areaOfStudy',
        'degreeLevel',
        'id',
        'returnUrl'
    ];
  
    override prepareExternalUrl(internal: string): string {
      const path = super.prepareExternalUrl(internal);
      const existingURLSearchParams = new URLSearchParams(this.search);
  
      const existingQueryParams = Object.fromEntries(
        existingURLSearchParams.entries()
      );

      this.blackListParams.forEach(item => {
        delete existingQueryParams[item]
      })
      
      const urlTree = this.urlSerializer.parse(path);
      const nextQueryParams = urlTree.queryParams;
  
      urlTree.queryParams = { ...existingQueryParams, ...nextQueryParams };
  
      return urlTree.toString();
    }
  }
  
import {gql} from 'apollo-angular'

const GET_QUOTES =  gql`
query quoteEntry ($ID: String!){
  quotes(id: $ID) {
    backgroundColor
    topDivider
    header
    headerTag
    introCopy {
      json
    }
    testimonialItemsCollection {
      items {
        ... on TestimonialItem {
          quote {
            json
          }
          author
          authorCredentials
          media {
            url
          }
          youTubeVideoLink
        }
      }
    }
    disclaimer {
      disclaimer {
        json
      }
    }
  }
}`

export {GET_QUOTES}

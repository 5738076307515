import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { PreviewModeService } from 'src/app/core/services/preview-mode.service';

@Component({
    selector: 'app-preview-overlay',
    templateUrl: './preview-overlay.component.html',
    styleUrls: ['./preview-overlay.component.less']
})
export class PreviewOverlayComponent implements OnInit {

    isPreviewMode;

    constructor(public preview: PreviewModeService,
        private router: Router,
        private activatedRoute: ActivatedRoute,) {
        this.isPreviewMode = this.preview.isPreviewMode();
    }

    ngOnInit(): void {
        this.preview.getValue().subscribe(data => {
            this.isPreviewMode = data;
            console.log('Exiting preview mode', data);
        })
    }

    /**
     * To exit the preview mode
     */
    exitPreviewMode() {
        const queryParams: Params = { preview: null };
        // to remove the query params from url
        this.router.navigate(
            [],
            {
                relativeTo: this.activatedRoute,
                queryParams,
                queryParamsHandling: 'merge', // remove to replace all query params by provided
            });
        this.preview.exitPreviewMode();
        setTimeout(() => {
            window.location.reload();
        }, 100);
    }

}

import { NgModule } from '@angular/core';
import { HeroComponent } from './hero/hero.component';
import { HeroSifComponent } from './hero-sif/hero-sif.component';
import { SifFormComponent } from './sif-form/sif-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MultiColumnComponent } from './multi-column/multi-column.component';
import { ContentBlockComponent } from './content-block/content-block.component';
import { CoreModule } from '../core/core.module';
import { PreviewOverlayComponent } from './preview-overlay/preview-overlay.component';
import { ContentfulRichTextComponent } from './contentful-rich-text/contentful-rich-text.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { LinkComponent } from './link/link.component';
import { FooterCtaComponent } from './footer-cta/footer-cta.component';
import { QuotesComponent } from './quotes/quotes.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { BigPhotoComponent } from './big-photo/big-photo.component';
import { DisclaimerItemComponent } from './disclaimer-item/disclaimer-item.component';
import { HTagComponent } from './h-tag/h-tag.component';
import { HorizontalCalloutComponent } from './horizontal-callout/horizontal-callout.component';
import { NotesAndConditionsComponent } from './notes-and-conditions/notes-and-conditions.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ContentBlockFullComponent } from './content-block-full/content-block-full.component';
import { ContentBlockRightRailComponent } from './content-block-right-rail/content-block-right-rail.component';
import { LargeTwoColumnComponent } from './large-two-column/large-two-column.component';
import { StartDatesComponent } from './start-dates/start-dates.component';
import { HorizontalTabsComponent } from './horizontal-tabs/horizontal-tabs.component';
import { HorizontalTabbedContentComponent } from './horizontal-tabbed-content/horizontal-tabbed-content.component';
import { FaqAccordionComponent } from './faq-accordion/faq-accordion.component';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { VerticalTabsComponent } from './vertical-tabs/vertical-tabs.component';
import { ModalComponent } from './modal/modal.component';

@NgModule({
  declarations: [
    HeroComponent,
    MultiColumnComponent,
    ContentBlockComponent,
    PreviewOverlayComponent,
    ContentfulRichTextComponent,
    HeaderComponent,
    FooterComponent,
    LinkComponent,
    FooterCtaComponent,
    QuotesComponent,
    HTagComponent,
    BigPhotoComponent,
    DisclaimerItemComponent,
    HorizontalCalloutComponent,
    NotesAndConditionsComponent,
    ContentBlockFullComponent,
    ContentBlockRightRailComponent,
    LargeTwoColumnComponent,
    StartDatesComponent,
    ModalComponent,
    FaqAccordionComponent,
    HorizontalTabsComponent,
    HorizontalTabbedContentComponent,
    VerticalTabsComponent,
    HeroSifComponent,
    SifFormComponent
  ],
  imports: [
    CoreModule,
    SlickCarouselModule,
    BrowserAnimationsModule,
    CdkAccordionModule,
    ReactiveFormsModule
  ],
  exports: [
    HeroComponent,
    MultiColumnComponent,
    ContentBlockComponent,
    PreviewOverlayComponent,
    HeaderComponent,
    FooterComponent,
    LinkComponent,
    FooterCtaComponent,
    QuotesComponent,
    BigPhotoComponent,
    HorizontalCalloutComponent,
    NotesAndConditionsComponent,
    ContentBlockFullComponent,
    ContentBlockRightRailComponent,
    LargeTwoColumnComponent,
    StartDatesComponent,
    HorizontalTabsComponent,
    HorizontalTabbedContentComponent,
    HTagComponent,
    DisclaimerItemComponent,
    ContentfulRichTextComponent,
    FaqAccordionComponent,
    ModalComponent,
    VerticalTabsComponent,
    HeroSifComponent,
    SifFormComponent
  ]
})
export class ComponentsModule { }

import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Constants } from 'src/app/config/app.config';
import { QueryStringService } from './query-string.service';
import { CookieService } from './cookie.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class GeoLocationService {

    public isDomestic = new BehaviorSubject<boolean>(true);
    public isDomestic$ = this.isDomestic.asObservable();

    constructor(
        private http: HttpClient,
        private cookieHandler: CookieService, 
        private queryStringHandler: QueryStringService,
        private constants: Constants) { }

    /**
     * @description Set the Location of the user using session cookies or an API call
     * @param {Function(bool)} call - THIS CALLBACK FUNCTION IS ONLY CALLED IF LOCATION IS NOT DOMESTIC.
     */
    setLocation() {
        try {
            const testTypeValue = this.queryStringHandler.getParameter("testtype");
            const isValidTestType = testTypeValue && (testTypeValue == 'domestic' || testTypeValue == 'international');

            if (isValidTestType) {
                const isInternational = testTypeValue == 'international';
                if (isInternational) {
                    this.isDomestic.next(false);
                }
                return;
            }

            // TODO - fix the delay caused when reading from cookie
            const domesticCookieValue = this.cookieHandler.getCookie('isDomestic');
            if (domesticCookieValue) {
                if (domesticCookieValue === 'false') {
                    this.isDomestic.next(false);
                }
            } 
            else {
                this.requestLocation().subscribe((location: any) => {
                    const country_code = location.country_code;
                    console.log("country " + country_code);
                    const isDomestic = (country_code === "US") ? true : false;
                    this.cookieHandler.createCookie('isDomestic', isDomestic.toString(), 3);
                    if (isDomestic && location.region_code && (location.region_code !== 'null')) {
                        this.cookieHandler.createCookie('region_code', location.region_code, 3);
                    }
                    if (!isDomestic) {
                        this.isDomestic.next(false);
                    }
                })
            }
        } catch (e: any) {
            console.log("Error identifying user location " + e.message);
        }
    }

    /**
     * Make a request to IpStack API.
     * @param {Function} successFn A function to be called if the request succeeds
     */
    requestLocation() {
        return this.http.get(this.constants.IpStackEndPoint);
    }

 
}

import { gql } from "apollo-angular";
import { DISCLAIMER_ITEM_FIELDS } from "./disclaimer-item";


export const  SIF_FORM = gql`
  fragment SIF_FORM on SifFullFormUMass {
    header
    hTag
    subhead
    zipCodeLabel
    zipErrorMessage
    degreeLevelLabel
    degreeLevelErrorMessage
    aosLabel
    aosErrorMessage
    programLabel
    programErrorMessage
    sessionLabel
    sessionErrorMessage
    firstNameLabel
    firstNameErrorMessage
    lastNameLabel
    lastNameErrorMessage
    emailLable
    emailErrorMessage       
    countryregionLabel
    countryregionErrorMessage
    countryCodeLabel
    countryCodeErrorMessage
    phoneLabel
    phoneErrorMessage
    linkTitle
    militaryQuestionLabel
    submitLoaderText
    tcpaDisclaimer {
      ... DISCLAIMER_ITEM_FIELDS
    }
    tooltipTitle
    tooltipCopy {
      json
    }
    gdprDisclaimer {
      ... DISCLAIMER_ITEM_FIELDS
    }
    gdprCheckBoxNotice {
      json
    }
    scheduleAnAppointment {
      header
      content {
        json
      }
      linksCollection {
        items {
          ... LINK_FIELDS
        }
      }
    }
    thankYou {
      header
      content {
        json
      }
      linksCollection {
        items {
          ... LINK_FIELDS
        }
      }
    }
    suppressionThankYou {
      header
      content {
        json
      }
      linksCollection {
        items {
          ... LINK_FIELDS
        }
      }
    }
  }
  
  ${DISCLAIMER_ITEM_FIELDS}`;

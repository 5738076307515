import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ImageAlignment } from 'src/app/config/app.config';
import { ContentBlockComponent } from '../content-block/content-block.component';

@Component({
  selector: 'app-content-block-full',
  templateUrl: './content-block-full.component.html',
  styleUrls: ['./content-block-full.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class ContentBlockFullComponent extends ContentBlockComponent implements OnInit {
  isImageLeftAligned = false;
  backgroundColor = '';

  constructor() {
    super();
  }

  override ngOnInit(): void {
    this.isImageLeftAligned = this.contentBlock.imageAlignment == ImageAlignment.Left;
    this.isMediaPresent = this.checkIfMediaExist();
    if (this.contentBlock.videoYouTubeLink) {
      this.thumbnailSrc = `https://img.youtube.com/vi/${this.contentBlock.videoYouTubeLink}/maxresdefault.jpg`;
      this.videoSrc = `https://www.youtube.com/embed/${this.contentBlock.videoYouTubeLink}`;
    }
    this.backgroundColor = this.getBackgroundColorClass(this.contentBlock.backgroundColor?.color);
  }

  getBackgroundColorClass(bckgrd: string): string {
    return bckgrd ? bckgrd : "White";
  }

}

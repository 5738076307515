

<header #header class="header" role="banner">
  <app-header></app-header>
</header>
<main>
  <router-outlet></router-outlet>
</main>
<app-footer></app-footer>
<app-preview-overlay></app-preview-overlay>

import { gql } from "apollo-angular";
import { CONTENT_BLOCK_FULL_FIELDS } from "./content-block-full"
import { DISCLAIMER_ITEM_FIELDS } from "./disclaimer-item";

export const VERTICAL_TAB_FIELDS = gql`
fragment VERTICAL_TAB_FIELDS on VerticalTabs {
  backgroundColor
  header
  headerTag
  title
  topDivider
  introCopy {
    json
  }
  tabbedContentCollection (limit:4) {
    items {
      ...CONTENT_BLOCK_FULL_FIELDS
    }
  }
  rightColumnItemListCollection (limit:1) {
    items {
      ...CONTENT_BLOCK_FULL_FIELDS
    }
  }
  disclaimer{
    ...DISCLAIMER_ITEM_FIELDS
  }
}
${CONTENT_BLOCK_FULL_FIELDS}
${DISCLAIMER_ITEM_FIELDS}`;
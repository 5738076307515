  <div class="site-header">
    <div class="cta-container mobile">
      <ng-container *ngIf="isMobile; then headerCtaContainer"></ng-container>
    </div>
    <div class="grid-container wide">
      <div class="grid-x align-justify align-center align-middle">
        <div class="cell medium-3  small-12 logo-container">
          <ng-container *ngIf="headerData.logoLink then logoLink else logo"></ng-container>
            <ng-template #logo>
              <img [src]="headerData.logo?.url" alt="Logo University of Massachusetts Global">
            </ng-template>
            <ng-template #logoLink>
              <a tabindex="0" [routerLink]="headerData.logoLink" *ngIf="headerData.logoLink == '/'; else externalLink">
                <img [src]="headerData.logo?.url" alt="Logo University of Massachusetts Global">
              </a>
              <ng-template #externalLink>
                <a tabindex="0" [href]="headerData.logoLink">
                  <img [src]="headerData.logo?.url" alt="Logo University of Massachusetts Global">
                </a>
              </ng-template>
            </ng-template>
        </div>
        <div class="cell medium-9 small-12 right-cta">
          <div class="cta-container tablet">
            <ng-container *ngIf="!isMobile; then headerCtaContainer"></ng-container>
          </div>
        </div>
      </div>
      
    </div>
  </div>

  <ng-template #headerCtaContainer>    
      <div class="contact-us-wrapper" >        
        <button class="button contact-us" (click)="openContactUs()" [class.open]="isContactUsOpen" >Contact Us</button>
        <div #menuOption class="dropdown-menu-options" *ngIf="isContactUsOpen" >
          <a *ngIf="headerData?.contactUsPhoneNumber as phoneNumber" tabindex="0" [href]="'tel:'+phoneNumber" class="menu-item">{{phoneNumber}}</a>
          <a *ngIf="headerData?.contactUsChat && isChatLoaded" tabindex="0" href="javasscript:void(0)" (click)="triggerChat()" class="menu-item">{{headerData.contactUsChat}}</a>
          <a *ngIf="headerData?.contactUsLinkTitle" tabindex="0" [routerLink]="['/' + headerData.contactUsLink?.url]" class="menu-item">{{headerData.contactUsLinkTitle}}</a>
        </div>
      </div>
        
      <app-link *ngIf="headerData?.buttonLink1 as buttonLink1" [childClass]="'button button-primary'" 
        [internalLink]="buttonLink1.internalLink?.url" [linkTitle]="buttonLink1.linkTitle" [target]="buttonLink1.linkTarget" 
        [externalLink]="buttonLink1.externalLink" [documentLink]="buttonLink1.documentLink"></app-link>
      <app-link *ngIf="headerData?.buttonLink2 as buttonLink2" [childClass]="'button button-primary accent'" 
        [internalLink]="buttonLink2.internalLink?.url" [linkTitle]="buttonLink2.linkTitle" [target]="buttonLink2.linkTarget" 
        [externalLink]="buttonLink2.externalLink" [documentLink]="buttonLink2.documentLink"></app-link>
  </ng-template>
  

import { Injectable } from '@angular/core';
import UAParser from 'ua-parser-js';

@Injectable({
  providedIn: 'root'
})
export class UserAgentService {
  constructor() { }

  getUAParams() {
    const parser = new UAParser();
    const uaParams = parser.getResult();
    uaParams.device.type = this.setDeviceCategory(uaParams);
    return uaParams;
  }

  setDeviceCategory(params: any) {
    return params.device.type
      || params.ua.indexOf('Mobile') !== -1 && 'mobile'
      || params.ua.indexOf('Tablet') !== -1 && 'tablet'
      || 'desktop'
  }
}

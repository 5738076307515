<ng-container *ngIf="chatLink; else LinkButton">
    <a tabindex="0" [class]="childClass" href="javasscript:void(0)" (click)="triggerChat()">
        <ng-container *ngTemplateOutlet="linkInnerContent"></ng-container>
    </a>
</ng-container>
<ng-template #LinkButton>
    <ng-container *ngIf="internalLink then internal else external"></ng-container>
    <ng-template #internal>
        <a tabindex="0" [class]="childClass" [target]="target == '_blank' ? target : '_self'" [routerLink]="['/' + internalLink]">
            <ng-container *ngTemplateOutlet="linkInnerContent"></ng-container>
        </a>
    </ng-template>
    <ng-template #external>
        <ng-container *ngIf="externalLink; else document">
            <a tabindex="0" [class]="childClass" [target]="target == '_blank' ? target : '_self'" [href]="externalLink">
                <ng-container *ngTemplateOutlet="linkInnerContent"></ng-container>
            </a>
        </ng-container>
        <ng-template #document>
            <a tabindex="0" [class]="childClass" [target]="target == '_blank' ? target : '_self'" [href]="documentLink">
                <ng-container *ngTemplateOutlet="linkInnerContent"></ng-container>
            </a>
        </ng-template>
    </ng-template>
</ng-template>


<ng-template #linkInnerContent>
    <ng-container *ngIf="linkContent; else  linkText" >
        <ng-container *ngTemplateOutlet="linkContent"></ng-container>
    </ng-container>
    <ng-template #linkText>{{linkTitle}}</ng-template>
</ng-template>


<section class='divider' [class]="quotes.backgroundColor" *ngIf="quotes?.topDivider">
    <div class="grid-container">
        <div class="line-break"></div>
    </div>
</section>
<section [ngClass]="[backgroundColor]" *ngIf="quotes">
    <div class="quotes">
        <div class='grid-container'>
            <div class='grid-x'>
                <div class='small-12' *ngIf="quotes?.header || quotes?.introCopy?.json">
                    <app-h-tag [hTag]="quotes.headerTag" [className]="'header'" [content]="quotes.header"></app-h-tag>
                    <div class='text-content' *ngIf="quotes?.introCopy?.json">
                        <app-contentful-rich-text [document]="quotes?.introCopy?.json"></app-contentful-rich-text>
                    </div>
                </div>
                <div class='small-12 t-slider' *ngIf="quotes?.testimonialItemsCollection?.items?.length > 0">
                    <ngx-slick-carousel class="carousel" id="slick-carousel" #slickModal="slick-carousel" [config]="slideConfig">
                        <div ngxSlickItem *ngFor="let testimontal of quotes?.testimonialItemsCollection?.items"
                            class="slide">
                            <div class='container t-testimonial'>
                                <div class='grid-x'>
                                    <div class='small-12 medium-12 quote' [ngClass]="{'large-8 small-order-1 medium-order-1 large-order-2':testimontal?.media}">
                                        <div class='t-quote'>
                                            <app-contentful-rich-text
                                                [document]="testimontal?.quote?.json"></app-contentful-rich-text>
                                        </div>
                                        <div class='author-wrapper'>
                                            <p class='t-author'>
                                                {{testimontal.author}}
                                            </p>
                                            <p class='t-creds'>
                                                {{testimontal.authorCredentials}}
                                            </p>
                                        </div>
                                    </div>
                                    <div class='small-12 medium-12 large-4 small-order-2 medium-order-2 large-order-1 author' *ngIf="testimontal?.media">
                                        <div class='t-image-wrap'>
                                            <img loading='lazy' class='t-image' src='{{testimontal?.media?.url}}' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ngx-slick-carousel>
                </div>
            </div>
            <app-disclaimer-item [disclaimerItem]="quotes?.disclaimer"></app-disclaimer-item>
        </div>
    </div>
</section>
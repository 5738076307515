import { Component, Input, Inject, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-big-photo',
  templateUrl: './big-photo.component.html',
  styleUrls: ['./big-photo.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class BigPhotoComponent implements OnInit {
  @Input({ required: true }) public bigPhoto!: any;

  overlayClass: string = '';
  backgroundColor: string = '';
  imageStyles: any = {}
  textVerticalPositionClass: string = ''
  textHorizontalPositionClass: string = ''
  textAlignmentClass: string = ''

  constructor() {
  }

  ngOnInit(): void {
    this.overlayClass = 'overlay-' + this.getOverlayOpacity();
    this.backgroundColor = this.getBackgroundColorClass(this.bigPhoto?.backgroundColor);
    this.imageStyles = this.getImageStyles();
    this.textHorizontalPositionClass = this.getTextHorizontalPositionClass();
    this.textVerticalPositionClass = this.getTextVerticalPositionClass();
    this.textAlignmentClass = this.getTextAlignmentClass(this.bigPhoto?.textPositionHorizontal);
  }

  getImageStyles() {
    if (this.bigPhoto?.backgroundImage?.url) {
      return {
        'background-image': 'url(' + this.bigPhoto.backgroundImage.url + ')',
        'background-position-x': this.bigPhoto.horizontalPosition ? this.bigPhoto.horizontalPosition.toLowerCase() : 'center',
        'background-position-y': this.bigPhoto.verticalPosition ? this.bigPhoto.verticalPosition.toLowerCase() : 'center'
      }
    }

    return '';
  }

  getOverlayOpacity() {
    return this.bigPhoto?.imageOverlay != '' ? this.bigPhoto.imageOverlay.split('%')[0] : '0';
  }

  getTextVerticalPositionClass() {
    if (this.bigPhoto?.textPositionVertical != '') {
      let verticalPosition = this.bigPhoto.textPositionVertical;
      return 'align-' + (verticalPosition == 'Center' ? 'middle' : verticalPosition.toLowerCase());
    } else {
      return 'align-middle';
    }
  }

  getTextHorizontalPositionClass() {
    return this.bigPhoto?.textPositionHorizontal != ''
      ? 'align-' + this.bigPhoto.textPositionHorizontal.toLowerCase()
      : 'align-left';
  }

  getTextAlignmentClass(alignmentValue: string): string {
    let alignment = alignmentValue ? alignmentValue.toLowerCase() : 'left';
    return `text-${alignment}`;
  }

  getBackgroundColorClass(bckgrd: string): string {
    return bckgrd ? bckgrd : "White";
  }
} 

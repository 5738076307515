import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from 'src/app/core/services/common.service';

@Component({
  selector: 'app-content-block',
  templateUrl: './content-block.component.html',
  styleUrls: ['./content-block.component.less']
})
export class ContentBlockComponent implements OnInit {
  @Input({ required: true }) public contentBlock!: any;
  @Input() public showMedia: boolean = false;
  @Input() public buttonClass: string = 'btn primary-button';
  @Input() public headTagClass: string = 'header h2';
  thumbnailSrc  = '';
  videoSrc = '';
  isMediaPresent  = false;


  constructor() { 
  }

  ngOnInit(): void {
    this.isMediaPresent = this.checkIfMediaExist();
    if (this.contentBlock.videoYouTubeLink) {
      this.thumbnailSrc = `https://img.youtube.com/vi/${this.contentBlock.videoYouTubeLink}/maxresdefault.jpg`;
      this.videoSrc = `https://www.youtube.com/embed/${this.contentBlock.videoYouTubeLink}`;
    }
  }

  checkIfMediaExist() {
    return (this.contentBlock.image || this.contentBlock.video || this.contentBlock.videoYouTubeLink || this.contentBlock.faIcon);
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from 'src/app/core/services/common.service';

@Component({
  selector: 'app-disclaimer-item',
  templateUrl: './disclaimer-item.component.html',
  styleUrls: ['./disclaimer-item.component.less']
})
export class DisclaimerItemComponent implements OnInit {
  @Input({ required: true }) public disclaimerItem!: any;

  constructor(public commonService: CommonService) {

  }

  ngOnInit(): void {
  }
}

import { gql } from "apollo-angular";
import { LINK_FIELDS } from "./link";

export const HEADER_FIELDS = gql`
fragment HEADER_FIELDS on WebsiteHeader {
    logo {
        url
    }
    logoLink
    contactUsPhoneNumber
    contactUsChat
    contactUsLink {
        ... on OptimizedCombo{
            url
        }
    }
    contactUsLinkTitle
    buttonLink1 {
        ... LINK_FIELDS
    }
    buttonLink2 {
        ... LINK_FIELDS
    }
}
${LINK_FIELDS}`;

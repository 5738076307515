import { InjectionToken, NgModule, TransferState, makeStateKey } from "@angular/core";
import { APOLLO_OPTIONS } from "apollo-angular";
import { HttpLink } from "apollo-angular/http";
import { ApolloClientOptions, ApolloLink, DocumentNode, InMemoryCache } from "@apollo/client/core";
import { setContext } from "@apollo/client/link/context";
import { Constants } from "./config/app.config";
import { PreviewModeService } from "./core/services/preview-mode.service";
import { print } from "graphql";

const APOLLO_CACHE = new InjectionToken<InMemoryCache>('apollo-cache');
const STATE_KEY = makeStateKey<any>('apollo.state');

export function createApollo(httpLink: HttpLink, constant: Constants, transferState: TransferState, cache: InMemoryCache, preview: PreviewModeService): ApolloClientOptions<any> {
  // Set authentication header
  const auth = setContext((operation, context) => {
    return {
      headers: {
        Authorization: `Bearer ${preview.isPreviewMode() ? constant.ContentFulPreviewToken : constant.ContentFulAccessToken}`,
      },
    };
  });

  const isBrowser = transferState.hasKey<any>(STATE_KEY);

  if (isBrowser) {
    const state = transferState.get<any>(STATE_KEY, null);
    cache.restore(state);
  } else {
    transferState.onSerialize(STATE_KEY, () => {
      return cache.extract();
    });
    // Reset cache after extraction to avoid sharing between requests
    cache.reset();
  }

  const operationPrinter = (operation: DocumentNode) => {
    const printed = print(operation);
    const minified = printed.replace(/(\r\n|\n|\r)/gm, "").replace(/\s+/g, " ");
    return minified;
  }

  return {
    link: (ApolloLink.from([auth, httpLink.create({ uri: constant.APIBasePathContentFul, operationPrinter })])),
    cache,
    ssrMode: true,
    ssrForceFetchDelay: 50,
  };
}

@NgModule({
  providers: [
    {
      provide: APOLLO_CACHE,
      useValue: new InMemoryCache(),
    },
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, Constants, TransferState, APOLLO_CACHE, PreviewModeService],
    },

  ],
})
export class GraphQLModule { }

import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Constants } from 'src/app/config/app.config';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  private mutations: any
  private chatWidgetLoaded = new BehaviorSubject<boolean>(false);
  public chatWidgetLoaded$ = this.chatWidgetLoaded.asObservable();
  public chatButtonClick = new Subject<boolean>();
  private chatWidget: any;

    constructor(@Inject(DOCUMENT) private dom: Document, private constants: Constants, private commonService: CommonService) {
      this.chatButtonClick.subscribe(data => {
        this.dom.getElementById('chat-button')?.click();
      })

      if(this.commonService.isPlatformBrowser()) {
        this.createMutationObserver();
      }
      
    }

    /**
     * To add the canonical url to the dom
     * @param url canonical url from CMS
     */
    addGenesysChatWidget() {
        const script: HTMLScriptElement = this.dom.createElement('script');
        script.setAttribute('id', 'widgets');
        script.setAttribute('endpoint', 'MSP');
        script.setAttribute('product', 'Revolution');
        script.setAttribute('src', this.constants.GenesysSrc)
        script.setAttribute('onload', `javascript:CXBus.configure({pluginsPath:'${this.constants.GenesysPluginsPath}'});CXBus.loadFile('${this.constants.GenesysLoadFile}').done(function(){CXBus.loadPlugin('widgets-core')});`);
        this.dom.body.appendChild(script);
    }

    /**
     * Creates a mutation observer and listens for chat widget load
     */
    createMutationObserver() {
      this.mutations = new MutationObserver((mutations: MutationRecord[]) => {
        mutations.forEach((mutation: MutationRecord) => {
          if (mutation.type === "childList" && mutation.addedNodes?.length>0) {
            mutation.addedNodes.forEach((node: any) => {
              if(node.className?.includes('cx-widget') || node.className?.includes('cx-window-manager')) {
                if(window._genesys) {
                  this.chatWidget = window._genesys.widgets.bus.registerPlugin('ChatPlugin');
                }
                // Trigger the event so that the other chat buttons (in header or in content) can be displayed. 
                this.chatWidgetLoaded.next(true);
                // Stops listening for mutation events.
                this.mutations.disconnect();
              }
            })
          }
        });
      });

      // Starts listening to the mutation created above.
      this.mutations.observe(this.dom.body, {
        childList: true,
        characterData: true
      });
    }

    /**
     * To show or hide the chat widget
     * @param command widget command
     */
    showOrHideChat(command: 'hideChatButton' | 'showChatButton') {
      if(this.chatWidget) {
        this.chatWidget.command('WebChat.' + command);
        this.dom.querySelector('.cx-widget')?.classList.toggle('hideChatButton');
      } else {
        const chatLoaded: any = this.chatWidgetLoaded$.subscribe(data=> {
          if (data) {
            setTimeout(()=> {
              this.dom.querySelector('.cx-widget')?.classList.toggle('hideChatButton');
              this.chatWidget.command('WebChat.' + command).done(function(e: any) {
                chatLoaded.unsubscribe();              
              }).fail(function(e: any){
                console.warn("failed to" + command , e);
              });
            }, 250);
          }
        });
      }
    }
}

declare global {
  interface Window {
    _genesys: any;
  }
}
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FooterData, HeaderFooterService } from 'src/app/core/services/header-footer.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class FooterComponent implements OnInit {
  footerData: FooterData = {} as FooterData;

  constructor(private footerService: HeaderFooterService) {
  }

  ngOnInit(): void {
    this.footerService.footerConfiguration.subscribe((data: FooterData | false) => {
      // if value 'false' is passed then use the defaultFooterData from the service. This used for the override purpose.
      if (data) {
        this.footerData = data;
      } else {     
        this.footerData = this.footerService.defaultFooterData;
      }    
    })
  }
}

<section class='divider' [class]="horizontalCallout.backgroundColor" *ngIf="horizontalCallout?.topDivider">
    <div class="grid-container">
      <div class="line-break"></div>
    </div>
</section>
<section [class]="horizontalCallout?.backgroundColor" *ngIf="horizontalCallout">
    <div class='horizontal-callout'>
        <div class='grid-container'>
            <div class='grid-x' [class]="noImageAlignmet">
                <div class='callout-content' [class]="noImageAlignmet">
                    <div class='image relative' *ngIf="horizontalCallout?.image">
                        <img loading='lazy' [attr.src]="horizontalCallout?.image== null ? '' : horizontalCallout?.image.url" />
                    </div>
                    <div class='icon relative' *ngIf="horizontalCallout?.faIcon">
                        <i class='fas fal {{horizontalCallout?.faIcon}}'></i>
                    </div>
                    <div class='text-content'>
                        <app-h-tag [hTag]="horizontalCallout.hTag" [className]="'header'" [content]="horizontalCallout.header"></app-h-tag>
                        <app-contentful-rich-text [document]="horizontalCallout?.mainContent?.json"></app-contentful-rich-text>
                    </div>
                </div>
                <app-link *ngIf="horizontalCallout.linkTitle && (horizontalCallout.internalLink?.url || horizontalCallout.externalLink || horizontalCallout.documentLink)" [childClass]="'btn primary-button'" [internalLink]="horizontalCallout.internalLink?.url" 
                [linkTitle]="horizontalCallout.linkTitle" [target]="horizontalCallout.linkTarget">
                </app-link>
            </div>
        </div>
    </div>
</section>